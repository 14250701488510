import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'
import Button from '../../../components/Form/StyledButton'

import classes from './styles.module.scss'

export default function PaymentUnsuccessfullPage () {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  return (
    <div className={classes.PaymentUnsuccessfullPage}>
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <div className={classes.backgroundContainer} />
      <div className={classes.contentContainer}>
        <h1 className={classes.title}>Payment confirmation</h1>
        <div className={classes.failIcon} />
        <div className={classes.details}>
          <h2>Payment Unsuccessful!</h2>
          {query.get('message')
            ? (
            <p>{query.get('message')}</p>
              )
            : (
            <p>
              Opps! Something went wrong. <br />
              Please try again
            </p>
              )}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            label="Try Again"
            onClick={() => history.push('/dashboard/contracts')}
          />
        </div>
      </div>
    </div>
  )
}
