/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { connect } from 'react-redux'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Form, Tabs, Tab } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'

import * as customersActions from '../../../redux/actions/customer'
import * as usersActions from '../../../redux/actions/user'

import toFormErrors from '../../../helpers/toFormErrors'
import FormErrorMessage from '../../../components/Messages/ErrorMessage'
import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'
import Title from '../../../components/Account/Title'
import FormField from '../../../components/Form/FormField'
import CountryField from '../../../components/Form/CountryField'
import PhoneField from '../../../components/Form/PhoneField'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import FormButton from '../../../components/Form/StyledButton'
import ModalLayout from '../../../modals/ModalLayout'
import InformationSuccessModal from '../../../modals/InformationSuccess'
import Spinner from '../../../components/Spinner'

import { API_URL } from '../../../constants/main'

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your name'),
  lastName: Yup.string().required('Please enter your last name'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string().required('Please enter your country'),
  clientCode: Yup.string(),
  password: Yup.string().min(2, 'Too Short!'),
  passwordConfirmation: Yup.string()
    .min(2, 'Too Short!')
    .oneOf([Yup.ref('password'), null], 'Password mismatch')
})

const validationPhone = (value) => {
  let errorMessage
  if (!isValidPhoneNumber(value)) {
    errorMessage = value ? 'Invalid phone number' : 'Please enter your phone'
  }
  return errorMessage
}

let timer

function EditProfile ({
  isFetching,
  user,
  customer,
  getUser,
  getCustomer,
  changePasswordUser,
  patchUser,
  isUpdating
}) {
  const [modalShow, setModalShow] = useState(false)
  const [error, setErrors] = useState(null)
  const [clientIdsFields, setClientIdsFields] = useState(null)
  const [isConfirmationSMSSent, setIsConfirmationSMSSent] = useState(false)
  const [
    isPhoneConfirmationCodeCorrect,
    setIsPhoneConfirmationCodeCorrect
  ] = useState(false)

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (user) {
      getCustomer(user.pk)
    }
  }, [user])

  useEffect(() => {
    if (customer && !isFetching) {
      const clientIdsObject = {}
      const clientIds = customer.clientCode.split(',')
      clientIds.forEach((id, index) => {
        clientIdsObject[`clientCode${index + 1}`] = id
      })
      setClientIdsFields(clientIdsObject)
    }
  }, [customer])

  const requestPhoneConfirmationCode = async (phone) => {
    try {
      await axios.post(`${API_URL}/users/request-phone-confirmation`, {
        phone
      })
      setIsConfirmationSMSSent(true)
    } catch (err) {
      console.log(err)
    }
  }

  const confirmPhone = async (phoneConfirmationCode, setTouched, touched) => {
    try {
      if (phoneConfirmationCode.trim().length !== 4) {
        return
      }
      await axios.post(`${API_URL}/users/confirm-phone`, {
        phoneConfirmationCode
      })
      setIsPhoneConfirmationCodeCorrect(true)
      setIsConfirmationSMSSent(true)
      setTouched({ ...touched, phoneConfirmationCode: true })
    } catch (err) {
      console.log(err)
      setIsPhoneConfirmationCodeCorrect(false)
      setTouched({ ...touched, phoneConfirmationCode: true })
    }
  }

  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.EditProfilePage}>
          <div className={classes.col}>
            <Title text="Edit Profile" />
            {!isFetching && user && customer && clientIdsFields && (
              <Formik
                enableReinitialize
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  phone: customer.phone,
                  address: customer.address,
                  city: customer.city,
                  state: customer.state,
                  country: customer.country,
                  password: '',
                  passwordConfirmation: '',
                  phoneConfirmationCode: '',
                  ...clientIdsFields
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                  setErrors(null)
                  try {
                    if (values.password && values.passwordConfirmation) {
                      await changePasswordUser({
                        password: values.password
                      })
                    }
                    await patchUser({
                      ...values,
                      username: user.username,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      address: values.address,
                      city: values.city,
                      country: values.country,
                      phone: values.phone ? values.phone : '',
                      state: values.state,
                      additionalClientCode: values.additionalClientCode
                    })

                    getUser()
                    setModalShow(true)
                    setErrors(null)
                    setIsConfirmationSMSSent(false)
                    setIsPhoneConfirmationCodeCorrect(false)
                  } catch (e) {
                    setErrors(toFormErrors(e))
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setTouched
                  // isSubmitting,
                }) => (
                  <Tabs
                    defaultActiveKey="personalInfo"
                    id="uncontrolled-tab-example"
                    className={classes.tabsContainer}
                  >
                    <Tab
                      eventKey="personalInfo"
                      title="Personal Information"
                      tabClassName={`${classes.tabItem}`}
                    >
                      <Form className={classes.form} noValidate>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="First name"
                            type="text"
                            placeholder="Enter first name"
                            value={values.firstName}
                            onChange={handleChange('firstName')}
                            onBlur={handleBlur('firstName')}
                          />
                          {touched.firstName && errors.firstName
                            ? (
                            <ErrorMessage message={errors.firstName} />
                              )
                            : null}
                        </div>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="Last Name"
                            type="text"
                            placeholder="Enter last name"
                            value={values.lastName}
                            onChange={handleChange('lastName')}
                            onBlur={handleBlur('lastName')}
                          />
                          {touched.lastName && errors.lastName
                            ? (
                            <ErrorMessage message={errors.lastName} />
                              )
                            : null}
                        </div>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="Email"
                            type="email"
                            placeholder="Enter email"
                            value={values.email}
                            onChange={handleChange('email')}
                            onBlur={handleBlur('email')}
                          />
                          {touched.email && errors.email
                            ? (
                            <ErrorMessage message={errors.email} />
                              )
                            : null}
                        </div>
                        <div
                          className={classNames(
                            classes.formFieldContainer,
                            isPhoneConfirmationCodeCorrect && classes.isValid
                          )}
                        >
                          <Field
                            label="Phone number"
                            type="text"
                            name="phone"
                            onChange={handleChange('phone')}
                            onBlur={handleBlur('phone')}
                            component={PhoneField}
                            validate={validationPhone}
                          />
                          {touched.phone && errors.phone
                            ? (
                            <ErrorMessage message={errors.phone} />
                              )
                            : null}
                        </div>
                        {!errors.phone &&
                          !isPhoneConfirmationCodeCorrect &&
                          (!customer.isPhoneConfirmed ||
                            values.phone !== customer.phone) && (
                            <div className={classes.formFieldContainer}>
                              <FormButton
                                disabled={isConfirmationSMSSent}
                                label="Validate phone number"
                                secondary
                                onClick={() =>
                                  requestPhoneConfirmationCode(values.phone)
                                }
                              />
                            </div>
                        )}
                        {isConfirmationSMSSent &&
                          !isPhoneConfirmationCodeCorrect && (
                            <div className={classes.formFieldContainer}>
                              <FormField
                                label="Phone Validation Code"
                                type="text"
                                placeholder="Enter phone validation code"
                                onChange={handleChange('phoneConfirmationCode')}
                                onBlur={handleBlur('phoneConfirmationCode')}
                                onKeyUp={() => {
                                  clearTimeout(timer)
                                  timer = setTimeout(() => {
                                    confirmPhone(
                                      values.phoneConfirmationCode,
                                      setTouched,
                                      touched
                                    )
                                  }, 200)
                                }}
                              />
                              {touched.phoneConfirmationCode &&
                              !isPhoneConfirmationCodeCorrect
                                ? (
                                <ErrorMessage message="Invalid code" />
                                  )
                                : null}
                            </div>
                        )}
                        {Object.keys(clientIdsFields).map((key, index) => {
                          return (
                            <>
                              <div
                                className={classes.formFieldContainer}
                                key={key}
                              >
                                <FormField
                                  label="Client ID"
                                  type="text"
                                  placeholder="Enter your client ID"
                                  value={values[`clientCode${index + 1}`]}
                                  onChange={handleChange(
                                    `clientCode${index + 1}`
                                  )}
                                  onBlur={handleBlur(`clientCode${index + 1}`)}
                                />
                                {touched[`clientCode${index + 1}`] &&
                                errors[`clientCode${index + 1}`]
                                  ? (
                                  <ErrorMessage
                                    message={errors[`clientCode${index + 1}`]}
                                  />
                                    )
                                  : null}
                              </div>
                            </>
                          )
                        })}
                        {customer.clientCode && (
                          <div className={classes.formFieldContainer}>
                            <FormField
                              label="Additional Client ID"
                              type="text"
                              placeholder="Enter your client ID"
                              value={values.additionalClientCode}
                              onChange={handleChange('additionalClientCode')}
                              onBlur={handleBlur('additionalClientCode')}
                            />
                            {touched.additionalClientCode &&
                            errors.additionalClientCode
                              ? (
                              <ErrorMessage
                                message={errors.additionalClientCode}
                              />
                                )
                              : null}
                          </div>
                        )}
                        <div
                          className={classes.formFieldContainer}
                          style={{ marginBottom: 51 }}
                        >
                          {error && <FormErrorMessage errors={error} />}
                        </div>
                        <div className={classes.formFieldContainer}>
                          {isUpdating
                            ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <Spinner />
                            </div>
                              )
                            : (
                            <FormButton label="Save" onClick={handleSubmit} />
                              )}
                        </div>
                      </Form>
                    </Tab>
                    <Tab
                      eventKey="addressInfo"
                      title="Address Information"
                      tabClassName={`${classes.tabItem}`}
                    >
                      <Form className={classes.form} noValidate>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="Billing Address"
                            type="text"
                            placeholder="Enter billing address"
                            onChange={handleChange('address')}
                            onBlur={handleBlur('address')}
                            value={values.address}
                          />
                          {touched.address && errors.address
                            ? (
                            <ErrorMessage message={errors.address} />
                              )
                            : null}
                        </div>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="City"
                            type="text"
                            placeholder="Enter your city"
                            value={values.city}
                            onChange={handleChange('city')}
                            onBlur={handleBlur('city')}
                          />
                          {touched.city && errors.city
                            ? (
                            <ErrorMessage message={errors.city} />
                              )
                            : null}
                        </div>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="State"
                            type="text"
                            placeholder="Enter your state"
                            value={values.state}
                            onChange={handleChange('state')}
                            onBlur={handleBlur('state')}
                          />
                          {touched.state && errors.state
                            ? (
                            <ErrorMessage message={errors.state} />
                              )
                            : null}
                        </div>
                        <div
                          className={classes.formFieldContainer}
                          style={{ marginBottom: 51 }}
                        >
                          <CountryField
                            label="Country"
                            name="country"
                            type="text"
                            placeholder="Enter your country"
                            value={values.country}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur('country')}
                          />
                          {touched.country && errors.country
                            ? (
                            <ErrorMessage
                              message={
                                values.country === ''
                                  ? 'Please enter your country'
                                  : errors.country
                              }
                            />
                              )
                            : null}
                        </div>
                        <div className={classes.formFieldContainer}>
                          {error && <FormErrorMessage errors={error} />}
                        </div>
                        <div className={classes.formFieldContainer}>
                          {isUpdating
                            ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <Spinner />
                            </div>
                              )
                            : (
                            <FormButton label="Save" onClick={handleSubmit} />
                              )}
                        </div>
                      </Form>
                    </Tab>
                    <Tab
                      eventKey="passwordChange"
                      title="Password Change"
                      tabClassName={`${classes.tabItem}`}
                    >
                      <Form className={classes.form} noValidate>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="New password"
                            type="password"
                            placeholder="Enter new password"
                            onChange={handleChange('password')}
                            onBlur={handleBlur('password')}
                            value={values.password}
                          />
                          {touched.password && errors.password
                            ? (
                            <ErrorMessage message={errors.password} outside />
                              )
                            : null}
                        </div>
                        <div className={classes.formFieldContainer}>
                          <FormField
                            label="Re-enter password"
                            type="password"
                            placeholder="Confirm password"
                            onChange={handleChange('passwordConfirmation')}
                            onBlur={handleBlur('passwordConfirmation')}
                            value={values.passwordConfirmation}
                          />
                          {touched.passwordConfirmation &&
                          errors.passwordConfirmation
                            ? (
                            <ErrorMessage
                              message={errors.passwordConfirmation}
                              outside
                            />
                              )
                            : null}
                        </div>
                        <div className={classes.formFieldContainer}>
                          {error && <FormErrorMessage errors={error} />}
                        </div>
                        <div className={classes.formFieldContainer}>
                          {isUpdating
                            ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <Spinner />
                            </div>
                              )
                            : (
                            <FormButton label="Save" onClick={handleSubmit} />
                              )}
                        </div>
                      </Form>
                    </Tab>
                  </Tabs>
                )}
              </Formik>
            )}
          </div>
        </div>
      </AppContainerWithMenu>
      <ModalLayout show={modalShow} onHide={() => setModalShow(false)}>
        <InformationSuccessModal text="Profile has been successfully updated" />
      </ModalLayout>
    </>
  )
}

export default connect(
  (state) => ({
    isFetching: state.customers.isFetching,
    isUpdating: state.users.isUpdating,
    user: state.users.user,
    customer: state.customers.customer
  }),
  {
    getUser: usersActions.getUser,
    patchUser: usersActions.patchUser,
    changePasswordUser: usersActions.changePasswordUser,
    getCustomer: customersActions.getCustomer
  }
)(EditProfile)
