import { GET_USER_SUCCESS, FETCHING, UPDATING } from '../types'

const initialState = {
  user: null,
  isFetching: false,
  isUpdating: false
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: payload
      }
    case FETCHING:
      return {
        ...state,
        isFetching: payload
      }
    case UPDATING:
      return {
        ...state,
        isUpdating: payload
      }
    default:
      return state
  }
}
