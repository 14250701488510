/* eslint-disable no-nested-ternary */
import React from 'react'

import moment from 'moment'

import classes from './styles.module.scss'
import waterIconMin from '../../../assets/images/waterMin.svg'
import lightningIconMin from '../../../assets/images/lightningMin.svg'
import phoneIcon from '../../../assets/images/phone.svg'

export default function PaymentDetailCard ({ invoice }) {
  return (
    <div className={classes.PaymentDetailCard}>
      <div className={classes.LeftWrap}>
        <div className={classes.boxImg}>
          {invoice.SOCIEDAD === 'AGUA'
            ? (
            <img src={waterIconMin} alt="Water" />
              )
            : invoice.SOCIEDAD === 'CCAR'
              ? (
            <img src={lightningIconMin} alt="Electricity" />
                )
              : (
            <img src={phoneIcon} style={{ width: 24 }} alt="Phone" />
                )}
        </div>
        <div className={classes.companyInfo}>
          <div className={classes.contactBox}>
            <span className={classes.label}>Factura: </span>
            <span className={classes.value}>{invoice.FACTURA}</span>
          </div>
          <div className={classes.contactBox}>
            <span className={classes.label}>Fecha: </span>
            <span className={classes.value}>
              {moment(invoice.FECHA_FAC).format('MMM/DD/YY')}
            </span>
          </div>
        </div>
      </div>
      <div className={classes.amountBox}>
        <span className={classes.amount}>Amount:</span>
        <span className={classes.amountValue}>
          ${parseFloat(invoice.SALDO).toLocaleString('en-US')}
        </span>
        <span className={classes.amountValue}>
          ${parseFloat(invoice.SALDODOP).toLocaleString('en-US')}
        </span>
      </div>
    </div>
  )
}
