import { FETCHING, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL } from '../types'
import CustomerService from '../../services/customer.service'

export const registerCustomer = (values) => () => {
  return CustomerService.registerCustomer(values)
    .then(() => {
      return Promise.resolve()
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

export const getCustomer = (userId) => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return CustomerService.getCustomer(userId)
    .then(({ data }) => {
      dispatch({
        type: GET_CUSTOMER_SUCCESS,
        payload: data
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: GET_CUSTOMER_FAIL
      })
      return Promise.reject(error.response)
    })
}

export const patchCustomer = (customerId, values) => (dispatch) => {
  return CustomerService.patchCustomer(customerId, values)
    .then(({ data }) => {
      dispatch({
        type: GET_CUSTOMER_SUCCESS,
        payload: data
      })
      return Promise.resolve()
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

export default {
  registerCustomer,
  getCustomer
}
