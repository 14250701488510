import React, { useContext } from 'react'

import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'
import { useMediaQuery } from 'react-responsive'
import { Scrollbars } from 'react-custom-scrollbars-2'
import moment from 'moment'

import GetCardType from '../../../helpers/identifyCardType'
import { PaymentContext } from '../../../context/index'
import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'
import Button from '../../../components/Form/StyledButton'
import PaymentDetailsCard from '../../../components/CheckOut/PaymentDetailsCard'
import classes from './styles.module.scss'

function PaymentConfirmationPage ({
  invoicesToPay,
  contractsToPay,
  customer,
  user
}) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  const { orderNumber, processedInvoices, cardHolderName } = useContext(
    PaymentContext
  )
  const history = useHistory()

  const cardBody = (
    <Card.Body className={classes.contentCardBody}>
      <Card.Title className={classes.contentCardTitle}>
        Payment confirmation
      </Card.Title>
      <div className={classes.successIcon} />
      <div className={classes.details}>
        <h2>Payment Successful!</h2>
        <p>
          Thank you! Your payment has been succesfull. A confirmation mail has
          been sent to {user.email}
        </p>
      </div>
      <Card className={classes.detailsCard}>
        <Card.Body className={classes.detailsCardBody}>
          <Card.Title className={classes.detailsCardTitle}>
            Payment details
          </Card.Title>
          <ul className={classes.paymentDetails}>
            <li>
              <span>Payment Date</span>
              <span>
                {moment(processedInvoices?.[0]?.value.createdAt)?.format(
                  'HH:MM DD/MMM/YYYY'
                )}
              </span>
            </li>
            <li>
              <span>Credit Card Holder</span>
              <span>{cardHolderName}</span>
            </li>
            <li>
              <span>Credit Card Type</span>
              <span>
                {GetCardType(processedInvoices?.[0]?.value?.cardNumber)}
              </span>
            </li>
            <li>
              <span>Credit Card</span>
              <span>{processedInvoices?.[0]?.value?.cardNumber.slice(-4)}</span>
            </li>
            <li>
              <span>Bank </span>
              <span>Payoneer</span>
            </li>
            <li>
              <span>Mobile</span>
              <span>{customer.phone}</span>
            </li>
            <li>
              <span>Email </span>
              <span>{user.email}</span>
            </li>
            <li>
              <span>Transaction Amount</span>
              <span>
                ${' '}
                {invoicesToPay
                  .reduce(
                    (accumulator, invoice) => accumulator + +invoice.SALDO,
                    0
                  )
                  .toLocaleString('en-US')}
              </span>
            </li>
            <li>
              <span>Transaction Amount DOP</span>
              <span>
                ${' '}
                {invoicesToPay
                  .reduce(
                    (accumulator, invoice) => accumulator + +invoice.SALDODOP,
                    0
                  )
                  .toLocaleString('en-US')}
              </span>
            </li>
            <li>
              <span>Confirmation number</span>
              <span>{processedInvoices?.[0]?.value?.authorizationNumber}</span>
            </li>
            <li>
              <span>CCPAY ref#</span>
              <span>{orderNumber}</span>
            </li>
          </ul>
          <div className={classes.buttonsContainer}>
            <div className={classes.buttonContainer}>
              <Button
                label="Print"
                secondary
                narrow
                onClick={() => history.push('/plus/print')}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                label="Close"
                narrow
                onClick={() => history.push('/dashboard/contracts')}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className={classes.detailsCardInfo}>
        <Card.Body className={classes.detailsCardBody}>
          <div className={classes.detailsWrapp}>
            {contractsToPay.contracts.map((contract) => {
              return invoicesToPay.find(
                (invoice) => invoice.CONTRATO === contract.CONTRATO
              )
                ? (
                <div className={classes.infoTop} key={contract.CONTRATO}>
                  <div className={classes.companyInfo}>
                    <div className={classes.topItem}>
                      <span className={classes.label}>{contract.SOCIEDAD}</span>
                    </div>
                    <div className={classes.contactBox}>
                      <span className={classes.label}>Contrato: </span>
                      <span className={classes.value}>{contract.CONTRATO}</span>
                    </div>
                    <div className={classes.contactBox}>
                      <span className={classes.label}>Inmuble: </span>
                      <span className={classes.value}>{contract.INMUEBLE}</span>
                    </div>
                  </div>
                  <div className={classes.cardWrapp}>
                    {invoicesToPay.map((invoice) => {
                      return invoice.CONTRATO === contract.CONTRATO &&
                        processedInvoices.some(
                          (elem) => elem.value.factura === invoice.FACTURA
                        )
                        ? (
                        <PaymentDetailsCard
                          invoice={invoice}
                          key={invoice.FACTURA}
                        />
                          )
                        : null
                    })}
                  </div>
                </div>
                  )
                : null
            })}
          </div>
        </Card.Body>
        <div className={classes.infoTotal}>
          <span className={classes.label}>TOTAL: </span>
          <span className={classes.value}>
            $
            {invoicesToPay
              .reduce((accumulator, invoice) => accumulator + +invoice.SALDO, 0)
              .toLocaleString('en-US')}{' '}
            USD
          </span>
          <span className={classes.value}>
            $
            {invoicesToPay
              .reduce(
                (accumulator, invoice) => accumulator + +invoice.SALDODOP,
                0
              )
              .toLocaleString('en-US')}{' '}
            DOP
          </span>
        </div>
      </Card>
    </Card.Body>
  )
  return (
    <div className={classes.PaymentConfirmationPage}>
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <div className={classes.backgroundContainer} />
      <div className={classes.contentContainer}>
        <Card
          className={classes.contentCard}
          style={{ height: isSmallScreen ? '' : 816 }}
        >
          {isSmallScreen
            ? (
                cardBody
              )
            : (
            <Scrollbars
              style={{ width: 828, height: 816 }}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    backgroundColor: '#F0F0F0',
                    borderRadius: 30,
                    width: 8,
                    position: 'relative',
                    right: 13
                  }}
                />
              )}
            >
              {cardBody}
            </Scrollbars>
              )}
        </Card>
      </div>
    </div>
  )
}

export default connect((state) => ({
  invoicesToPay: state.invoices.invoicesToPay,
  contractsToPay: state.contracts.contractsToPay,
  customer: state.customers.customer,
  user: state.users.user
}))(PaymentConfirmationPage)
