import React from 'react'

import Card from 'react-bootstrap/Card'
import { useMediaQuery } from 'react-responsive'

import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'
import Button from '../../../components/Form/StyledButton'

import classes from './styles.module.scss'

export default function PaymentConfirmationPage () {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  return (
    <div className={classes.PaymentConfirmationPage}>
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <div className={classes.backgroundContainer} />
      <div className={classes.contentContainer}>
        <Card className={classes.contentCard}>
          <Card.Body className={classes.contentCardBody}>
            <Card.Title className={classes.contentCardTitle}>
              Payment confirmation
            </Card.Title>
            <div className={classes.successIcon} />
            <div className={classes.details}>
              <h2>Payment Successful!</h2>
              <p>
                Thank you! Your payment has been succesfull. A confirmation mail
                has been sent to contact@email.com
              </p>
            </div>
            <Card className={classes.detailsCard}>
              <Card.Body className={classes.detailsCardBody}>
                <Card.Title className={classes.detailsCardTitle}>
                  Payment details
                </Card.Title>
                <ul className={classes.paymentDetails}>
                  <li>
                    <span>Bank </span>
                    <span>Payoneer</span>
                  </li>
                  <li>
                    <span>Mobile</span>
                    <span>+ 2 415 5552671</span>
                  </li>
                  <li>
                    <span>Email </span>
                    <span>contact@email.com</span>
                  </li>
                  <li>
                    <span>Amount paid</span>
                    <span>$ 40.00</span>
                  </li>
                  <li>
                    <span>Transaction ID</span>
                    <span>3359200483356</span>
                  </li>
                </ul>
                <div className={classes.buttonsContainer}>
                  <div className={classes.buttonContainer}>
                    <Button label="Print" secondary narrow />
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button label="Close" narrow />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
