import React, { useContext, useState, useEffect } from 'react'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { Card } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import classnames from 'classnames'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { PaymentContext } from '../../../context/index'

import FormField from '../../../components/Form/FormFieldWithInnerLabel'
import CheckBox from '../../../components/Form/CheckBox'
import CardNumberField from '../../../components/Form/CardNumberFieldWithInnerLabel'
import ExpCardDateField from '../../../components/Form/ExpCardDateFieldWithInnerLabel'
import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'
import Title from '../../../components/Account/Title'
import FormButton from '../../../components/Form/StyledButton'
import masterImg from '../../../assets/images/masterCardMin.svg'
import visaImg from '../../../assets/images/visaMini.svg'
import saveImg from '../../../assets/images/save.svg'
import * as cardActions from '../../../redux/actions/card'
import * as userActions from '../../../redux/actions/user'
import identifyCardType from '../../../helpers/identifyCardType'
import CountryField from '../../../components/Form/CountryField'

const digitsAndSpacesOnly = (value) => /[0-9 ]+/.test(value)
const digitsOnly = (value) => {
  return /^\d+$/.test(value)
}

const ValidationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required('Please enter card number')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(19),
  cardHolderName: Yup.string().required('Please enter your name'),
  expDate: Yup.string()
    .required('Please enter card expiration date')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(7),
  cvv: Yup.string()
    .required('Please enter card\'s cvv')
    .test('Digits only', 'The field should have digits only', digitsOnly)
    .length(3),
  address: Yup.string().required('Please enter your billing address'),
  state: Yup.string().required('Please enter your state'),
  country: Yup.string().required('Please enter your country'),
  city: Yup.string().required('Please enter your city'),
  postCode: Yup.string()
    .required('Please enter your post code')
    .test('Digits only', 'The field should have digits only', digitsOnly)
})

const ValidationSchemaCVVOnly = Yup.object().shape({
  cvv: Yup.string()
    .required('Please enter card\'s cvv')
    .test('Digits only', 'The field should have digits only', digitsOnly)
    .length(3)
})

export function CheckOutPage ({
  history,
  cards,
  getCards,
  user,
  getUser,
  invoicesToPay
}) {
  const [card, setCard] = useState('')
  const [
    isNewBillingAddressFormVisible,
    setIsNewBillingAdddressFormVisible
  ] = useState(false)

  const {
    orderNumber,
    cardNumber,
    setCardNumber,
    cardHolderName,
    setCardHolderName,
    expDate,
    setExpDate,
    cvv,
    setCvv,
    cardId,
    setCardId,
    shouldCardBeSaved,
    setShouldCardBeSaved,
    city,
    setCity,
    country,
    setCountry,
    postCode,
    setPostCode,
    state,
    setState,
    address,
    setAddress,
    shouldSaveAddress,
    setShouldSaveAddress
  } = useContext(PaymentContext)

  const saveCardData = (values, pushToNextPage = true) => {
    setCardNumber(values.cardNumber)
    setCardHolderName(values.cardHolderName)
    setExpDate(values.expDate)
    setCvv(values.cvv)
    setCity(values.city)
    setCountry(values.country)
    setPostCode(values.postCode)
    setState(values.state)
    setAddress(values.address)
    if (pushToNextPage) {
      history.push('/checkout/paymentDetails')
    }
  }

  if (!invoicesToPay.length) {
    history.replace('/')
  }

  useEffect(() => {
    getCards()
    getUser()
  }, [])

  useEffect(() => {
    if (card) {
      setIsNewBillingAdddressFormVisible(false)
      setCardNumber(card._embedded.instrumentIdentifier.card.number)
      setCardHolderName(`${card.billTo.firstName} ${card.billTo.lastName}`)
      setExpDate(`${card.card.expirationMonth} ${card.card.expirationYear}`)
      setCardId(card.id)
      setShouldCardBeSaved(true)
      setAddress(card.billTo.address1)
      setPostCode(card.billTo.postalCode)
      setCountry(card.billTo.country)
      setCity(card.billTo.locality)
      setState(card.billTo.administrativeArea)
      setCvv('')
    } else {
      setCardNumber('')
      setCardHolderName('')
      setExpDate('')
      setCardId('')
      setCvv('')
      setShouldCardBeSaved(false)
    }
  }, [card])

  useEffect(() => {
    if (isNewBillingAddressFormVisible) {
      setAddress('')
      setPostCode('')
      setCountry('DO')
      setCity('')
      setState('')
    } else if (!isNewBillingAddressFormVisible && user) {
      setAddress(user.address)
      setPostCode(user.postCode)
      setCountry(user.country)
      setCity(user.city)
      setState(user.state)
    }
  }, [isNewBillingAddressFormVisible, user])

  useEffect(() => {
    if (!user.city && !cards?.length) {
      setIsNewBillingAdddressFormVisible(true)
    }
  }, [user, cards])

  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.CheckOutPage}>
          <div className={classes.col}>
            <Card className={classes.boxCheckOutPage}>
              <Card.Body className={classes.boxCheckOutPageBody}>
                <div className={classes.title}>
                  <Title text="Check Out" />
                </div>
                <div className={classes.amountInfoWrapp}>
                  <div className={classes.amountInfoBox}>
                    <div className={classes.label}>
                      <span>Saved Cards</span>
                    </div>
                    <div className={classes.boxCard}>
                      {cards &&
                        cards.map((cardElem) => (
                          <div
                            data-tip={
                              cardElem._embedded.instrumentIdentifier.card
                                .number
                            }
                            key={cardElem.id}
                            className={
                              card.id === cardElem.id
                                ? classnames(
                                  classes.cardImg,
                                  classes.borderCard
                                )
                                : classes.cardImg
                            }
                            onClick={() => {
                              setCard((prevCard) => {
                                if (prevCard.id === cardElem.id) {
                                  return ''
                                }
                                return cardElem
                              })
                            }}
                          >
                            <img
                              src={
                                // eslint-disable-next-line no-nested-ternary
                                identifyCardType(
                                  cardElem._embedded.instrumentIdentifier.card
                                    .number
                                ).includes('Visa')
                                  ? visaImg
                                  : identifyCardType(
                                    cardElem._embedded.instrumentIdentifier
                                      .card.number
                                  ) === 'Mastercard'
                                    ? masterImg
                                    : null
                              }
                              alt="visa Card"
                            />
                            <div>
                              {cardElem._embedded.instrumentIdentifier.card.number.slice(
                                -4
                              )}
                            </div>
                            <ReactTooltip effect="solid" />
                          </div>
                        ))}
                    </div>
                  </div>
                  <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={{
                      orderNumber,
                      cardNumber,
                      cardHolderName,
                      expDate,
                      cvv,
                      address,
                      city,
                      country,
                      postCode,
                      state
                    }}
                    validationSchema={
                      cardId ? ValidationSchemaCVVOnly : ValidationSchema
                    }
                    onSubmit={(values) => {
                      saveCardData(values)
                    }}
                  >
                    {({
                      values,
                      // errors,
                      // touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                      setFieldValue
                      // dirty,
                      // isSubmitting,
                    }) => (
                      <Form className={classes.form}>
                        <div className={classes.titleForm}>
                          <span>Payment Details</span>
                        </div>
                        <div className={classes.titleFormMobile}>
                          <span>+ Add New Card</span>
                        </div>
                        <div className={classes.formRowContainer}>
                          <FormField
                            // name="cardHolderName"
                            label="Cardholder Name"
                            placeholder="Cardholder Name"
                            value={values.cardHolderName}
                            onChange={handleChange('cardHolderName')}
                            readOnly={cardId}
                            // onBlur={handleBlur('cardHolderName')}
                          />
                        </div>
                        <div className={classes.formRowContainer}>
                          <CardNumberField
                            readOnly={cardId}
                            label="Card Number"
                            placeholder="Card Number"
                            value={values.cardNumber}
                            onChange={handleChange('cardNumber')}
                            // onBlur={handleBlur('cardNumber')}
                          />
                        </div>
                        <div
                          className={`${classes.formRowContainer} ${classes.otherInfo}`}
                          style={{ justifyContent: card ? 'unset' : '' }}
                        >
                          <div
                            className={classes.exDate}
                            style={{ marginRight: card ? 15 : '' }}
                          >
                            <ExpCardDateField
                              readOnly={cardId}
                              label="Ex. Date"
                              placeholder="Ex. Date"
                              value={values.expDate}
                              onChange={handleChange('expDate')}
                              // onBlur={handleBlur('expDate')}
                            />
                          </div>
                          {card && (
                            <div className={classes.cvv}>
                              <FormField
                                label="CVV"
                                placeholder="CVV"
                                value={values.cvv}
                                onChange={handleChange('cvv')}
                                // onBlur={handleBlur('cvv')}
                              />
                            </div>
                          )}
                          {!card && (
                            <>
                              <div className={classes.cvv}>
                                <FormField
                                  readOnly={cardId}
                                  label="CVV"
                                  placeholder="CVV"
                                  value={values.cvv}
                                  onChange={handleChange('cvv')}
                                  // onBlur={handleBlur('cvv')}
                                />
                              </div>
                              <div
                                className={classnames(
                                  classes.saveBtn,
                                  shouldCardBeSaved && classes.active
                                )}
                                // eslint-disable-next-line consistent-return
                                onClick={() => {
                                  if (cardId) {
                                    return setShouldCardBeSaved(true)
                                  }
                                  setShouldCardBeSaved(
                                    (prevState) => !prevState
                                  )
                                }}
                              >
                                <img src={saveImg} alt="Save" />
                                <span className={classes.saveCard}>
                                  Saved card
                                </span>
                              </div>{' '}
                            </>
                          )}
                        </div>
                        {user?.city || cards?.length
                          ? (
                          <div
                            className={`${classes.formRowContainer} ${classes.checkBox}`}
                          >
                            <div>
                              <span className={classes.newAddress}>
                                New billing address
                              </span>
                            </div>
                            <div>
                              <CheckBox
                                checked={isNewBillingAddressFormVisible}
                                onChange={() => {
                                  saveCardData(values, false)
                                  if (cardId) {
                                    return
                                  }
                                  setIsNewBillingAdddressFormVisible(
                                    (prevState) => !prevState
                                  )
                                }}
                              />
                            </div>
                          </div>
                            )
                          : (
                          <div
                            className={`${classes.formRowContainer} ${classes.checkBox}`}
                          >
                            <div>
                              <span className={classes.newAddress}>
                                Save as account address
                              </span>
                            </div>
                            <div>
                              <CheckBox
                                checked={shouldSaveAddress}
                                onChange={() => {
                                  setShouldSaveAddress(
                                    (prevState) => !prevState
                                  )
                                }}
                              />
                            </div>
                          </div>
                            )}
                        <div
                          style={{
                            display: isNewBillingAddressFormVisible
                              ? 'block'
                              : 'none'
                          }}
                        >
                          <div className={classes.formRowContainer}>
                            <FormField
                              label="Address"
                              placeholder="Address"
                              value={values.address}
                              onChange={handleChange('address')}
                              readOnly={cardId}
                            />
                          </div>
                          <div className={classes.formRowContainer}>
                            <FormField
                              label="Post Code"
                              placeholder="Post Code"
                              value={values.postCode}
                              onChange={handleChange('postCode')}
                              readOnly={cardId}
                            />
                          </div>
                          <div className={classes.formRowContainer}>
                            <FormField
                              label="City"
                              placeholder="City"
                              value={values.city}
                              onChange={handleChange('city')}
                              readOnly={cardId}
                            />
                          </div>
                          <div className={classes.formRowContainer}>
                            <FormField
                              label="State"
                              placeholder="State"
                              value={values.state}
                              onChange={handleChange('state')}
                              readOnly={cardId}
                            />
                          </div>
                          <div className={classes.formRowContainer}>
                            <CountryField
                              label="Country"
                              name="country"
                              type="text"
                              placeholder="Country"
                              value={values.country}
                              setFieldValue={setFieldValue}
                              readOnly={cardId}
                              onBlur={handleBlur('country')}
                            />
                          </div>
                        </div>
                        <div className={classes.btnContainer}>
                          <FormButton
                            onClick={() => history.push('/dashboard/contracts')}
                            secondary
                            label="Cancel"
                          />
                          <FormButton
                            label="Continue"
                            onClick={handleSubmit}
                            disabled={!isValid}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </AppContainerWithMenu>
    </>
  )
}

export default connect(
  (state) => ({
    cards: state.cards.cards,
    user: state.users.user,
    invoicesToPay: state.invoices.invoicesToPay
  }),
  {
    getCards: cardActions.getCards,
    getUser: userActions.getUser
  }
)(CheckOutPage)
