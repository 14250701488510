import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'

import * as transactionActions from '../../../redux/actions/transaction'

import InvoiceDetailCard from '../../../components/Plus/InvoiceDetailCard'
import Button from '../../../components/Form/StyledButton'
import logo from '../../../assets/images/bigLogoNew.png'
import mark from '../../../assets/images/mark.svg'
import calendar from '../../../assets/images/calendar.svg'
import payment from '../../../assets/images/payment.svg'
import billNumber from '../../../assets/images/billNumber.svg'
import bank from '../../../assets/images/bankIcon.svg'

import classes from './styles.module.scss'

function InvoicePage ({ getOrder, order }) {
  const [formattedTransactions, setFormattedTransactions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalAmountDOP, setTotalAmountDOP] = useState(0)

  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  useEffect(() => {
    getOrder(query.get('order'))
  }, [])

  useEffect(() => {
    let amount = 0
    let amountDOP = 0
    if (order) {
      const contractsSet = new Set()
      order[0].Transactions.forEach((transaction) => {
        contractsSet.add(transaction.contrato)
        amount += +transaction.amount
        amountDOP += +transaction.amountDOP
      })

      const contracts = []

      contractsSet.forEach((contract) => {
        contracts.push({
          contract,
          transactions: order[0].Transactions.filter(
            (elem) => elem.contrato === contract
          )
        })
      })
      setFormattedTransactions(contracts)
      setTotalAmount(amount)
      setTotalAmountDOP(amountDOP)
    }
  }, [order])

  return (
    <>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
      </header>
      <div className={classes.contentWrapp}>
        <h1 className={classes.title}> Invoice: </h1>
        {formattedTransactions.length
          ? formattedTransactions.map((contract) => {
            return (
                <Card className={classes.invoiceCard} key={contract.contract}>
                  <Card.Body className={classes.invoiceCardBody}>
                    <div className={classes.invoiceWrapp}>
                      <div className={classes.orderInfo}>
                        <div className={classes.orderInfoItem}>
                          <div className={classes.boxImg}>
                            <img src={mark} alt="mark" />
                          </div>
                          <div className={classes.boxInfoText}>
                            <div className={classes.label}>
                              <span>Inmuble: </span>
                            </div>
                            <div className={classes.value}>
                              <span>{contract.transactions[0].inmueble}</span>
                            </div>
                          </div>
                        </div>
                        <div className={classes.orderInfoItem}>
                          <div className={classes.boxImg}>
                            <img src={calendar} alt="calendar" />
                          </div>
                          <div className={classes.boxInfoText}>
                            <div className={classes.label}>
                              <span>Fecha: </span>
                            </div>
                            <div className={classes.value}>
                              <span>
                                {moment(
                                  contract.transactions[0].createdAt
                                ).format('MMM/DD/YY')}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={classes.orderInfoItem}>
                          <div className={classes.boxImg}>
                            <img src={payment} alt="Payment" />
                          </div>
                          <div className={classes.boxInfoText}>
                            <div className={classes.label}>
                              <span>Payment: </span>
                            </div>
                            <div className={classes.value}>
                              <span>
                                XXXX-XXXX-XXXX-XX
                                {contract.transactions[0].cardNumber
                                  ? contract.transactions[0].cardNumber.slice(
                                    -2
                                  )
                                  : 'XX'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={classes.orderInfoItem}>
                          <div className={classes.boxImg}>
                            <img src={billNumber} alt="billNumber" />
                          </div>
                          <div className={classes.boxInfoText}>
                            <div className={classes.label}>
                              <span>Bills:</span>
                            </div>
                            <div className={classes.value}>
                              <span>
                                Number of bills: {contract.transactions.length}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={classes.orderInfoItem}>
                          <div className={classes.boxImg}>
                            <img src={bank} alt="bank" />
                          </div>
                          <div className={classes.boxInfoText}>
                            <div className={classes.label}>
                              <span>Authorization number: </span>
                            </div>
                            <div className={classes.value}>
                              <span>
                                {contract.transactions[0].authorizationNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.contentBox}>
                        <div className={classes.label}>
                          <span>{contract.transactions[0].sociedad}</span>
                        </div>
                        <div className={classes.boxInfoText}>
                          <span className={classes.label}>Contrato: </span>
                          <span className={classes.value}>
                            {contract.contract}
                          </span>
                        </div>
                      </div>
                      <div className={classes.invoiceItems}>
                        {contract.transactions.map((invoice) => {
                          return (
                            <InvoiceDetailCard
                              invoice={invoice}
                              key={invoice.id}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
            )
          })
          : null}
        <div className={classes.total}>
          <span className={classes.label}>TOTAL: </span>
          <span className={classes.value}>
            {parseFloat(totalAmount).toLocaleString('en-US')} USD
          </span>
          <span className={classes.value}>
            {parseFloat(totalAmountDOP).toLocaleString('en-US')} DOP
          </span>
        </div>

        <Button
          label="Back"
          onClick={() => {
            history.push('/history')
          }}
          style={{ marginBottom: 50 }}
        />
      </div>
    </>
  )
}

export default connect(
  (state) => ({
    order: state.transactions.order
  }),
  {
    getOrder: transactionActions.getOrder
  }
)(InvoicePage)
