import React, { useRef, useEffect, useState } from 'react'

import classnames from 'classnames'

import classes from './styles.module.scss'

export default function StyledButtonRed ({
  label,
  onClick,
  secondary,
  narrow,
  subtle,
  text,
  disabled,
  isLoading,
  style
}) {
  const [clickWidth, setClickWidth] = useState(null)
  const buttonRef = useRef()
  useEffect(() => {
    setClickWidth(buttonRef.current.clientHeight)
  }, [])
  const spinner = (
    <div
      className={classnames(
        classes.spinner,
        secondary && classes.secondary,
        subtle && classes.subtle,
        text && classes.text
      )}
    >
      <span className={classes.loadingMessage}>Loading...</span>
      <div />
      <div />
      <div />
      <div />
    </div>
  )

  return (
    <button
      className={classnames(
        classes.StyledButtonRed,
        secondary && classes.secondary,
        subtle && classes.subtle,
        text && classes.text,
        narrow && classes.narrow,
        isLoading && classes.isLoading
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      type="button"
      style={style}
      ref={buttonRef}
    >
      <div
        className={classes.click}
        style={{ width: buttonRef.current ? clickWidth * 0.86 : '100%' }}
      />
      {isLoading ? spinner : label}
    </button>
  )
}
