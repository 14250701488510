import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'

import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'
import Title from '../../../components/Account/Title'
import FormButton from '../../../components/Form/StyledButton'

import mark from '../../../assets/images/mark.svg'
import calendar from '../../../assets/images/calendar.svg'
import billNumber from '../../../assets/images/billNumber.svg'

function PaymentAmount ({ history, invoicesToPay, customer }) {
  if (!customer) {
    history.replace('/')
    return <></>
  }
  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.PaymentAmount}>
          <div className={classes.col}>
            <Card className={classes.boxPaymentAmount}>
              <Card.Body className={classes.boxPaymentAmountBody}>
                <div className={classes.title}>
                  <Title text="Order Details" />
                </div>
                <div className={classes.amountInfoWrapp}>
                  <div className={classes.amountInfoBox}>
                    <div className={classes.label}>
                      <span>Payment Amount</span>
                    </div>
                    <div className={classes.value}>
                      <span>
                        {invoicesToPay
                          .reduce(
                            (accumulator, invoice) =>
                              accumulator + +invoice.SALDO,
                            0
                          )
                          .toLocaleString('en-US')}{' '}
                        USD
                      </span>
                      <span>
                        {invoicesToPay
                          .reduce(
                            (accumulator, invoice) =>
                              accumulator + +invoice.SALDODOP,
                            0
                          )
                          .toLocaleString('en-US')}{' '}
                        DOP
                      </span>
                    </div>
                  </div>
                  <div className={classes.orderInfo}>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={mark} alt="mark" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Address:</span>
                        </div>
                        <div className={classes.value}>
                          <span>
                            {customer.city}, {customer.address}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={calendar} alt="calendar" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Month:</span>
                        </div>
                        <div className={classes.value}>
                          <span>{moment().format('MMMM')}</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={billNumber} alt="billNumber" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Bill Number:</span>
                        </div>
                        <div className={classes.value}>
                          <span>{invoicesToPay.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.btnContainer}>
                  <FormButton
                    onClick={() => history.push('/dashboard/contracts')}
                    secondary
                    label="Cancel"
                  />
                  <FormButton
                    label="Continue"
                    onClick={() => history.push('/checkout/checkOut')}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </AppContainerWithMenu>
    </>
  )
}

export default connect((state) => ({
  invoicesToPay: state.invoices.invoicesToPay,
  customer: state.customers.customer
}))(PaymentAmount)
