import axios from 'axios'

const getTransactions = () => {
  return axios.get('/transactions')
}

const getOrder = (orderId) => {
  return axios.get(`/transactions/order?id=${orderId}`)
}

export default {
  getTransactions,
  getOrder
}
