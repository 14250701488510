import React from 'react'

import classes from './styles.module.scss'

export default function CheckBox ({ label, checked, onChange }) {
  return (
    /* eslint-disable */
    <label className={classes.CheckBox}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={classes.checkmark} />
      {label}
    </label>
    /* eslint-disable */
  );
}
