import {
  GET_CONTRACTS_FAIL,
  GET_CONTRACTS_SUCCESS,
  ADD_CONTRACT_TO_PAY,
  REMOVE_CONTRACT_TO_PAY,
  RESET_CONTRACTS_TO_PAY,
  FETCHING,
  SET_CONTRACTS_TO_PAY_COMPANY_NAME
} from '../types'

import ContractService from '../../services/contract.service'

export const getContracts = () => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return ContractService.getContracts()
    .then(({ data }) => {
      dispatch({
        type: GET_CONTRACTS_SUCCESS,
        payload: data
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: GET_CONTRACTS_FAIL
      })
      return Promise.reject(error.response)
    })
}

export const addContractToPay = (contract) => (dispatch) => {
  dispatch({
    type: ADD_CONTRACT_TO_PAY,
    payload: contract
  })
}

export const removeContractToPay = (contract) => (dispatch) => {
  dispatch({
    type: REMOVE_CONTRACT_TO_PAY,
    payload: contract
  })
}

export const setContractsToPayCompanyName = (companyName) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_TO_PAY_COMPANY_NAME,
    payload: companyName
  })
}

export const resetContractsToPay = () => (dispatch) => {
  dispatch({
    type: RESET_CONTRACTS_TO_PAY
  })
}
