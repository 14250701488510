import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.scss'

export default function ErrorMssage ({ message, outside }) {
  const phoneError =
    message === 'phone must be a valid phone number.'
      ? 'Invalid phone number'
      : null
  return (
    <div
      className={classnames(classes.ErrorMessage, outside && classes.outside)}
    >
      {phoneError || message}
    </div>
  )
}
