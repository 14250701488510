import React from 'react'

import { connect } from 'react-redux'
// import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'

import authActions from '../../../redux/actions/auth'

import ModalLayout from '../../../modals/ModalLayout'
import InformationSendEmail from '../../../modals/InformationSendEmail'
import EmptyHeaderWithLogo from '../../../components/EmptyHeaderWithLogo'
import FormField from '../../../components/Form/FormField'
// import CheckBox from '../../../components/Form/CheckBox';
import FormButton from '../../../components/Form/StyledButton'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import toFormErrors from '../../../helpers/toFormErrors'
import FormErrorMessage from '../../../components/Messages/ErrorMessage'

import classes from './styles.module.scss'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email')
})

function PasswordResetPage ({ resetPasswordUser }) {
  const [modalShow, setModalShow] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [error, setErrors] = React.useState(null)

  const history = useHistory()

  const isMobile = useMediaQuery({ query: '(max-width: 570px)' })
  return (
    <div className={classes.PasswordResetPage}>
      <EmptyHeaderWithLogo biggerVariant incorrectPasswordVariant />
      <div className={classes.contentContainer}>
        <p className={classes.details}>
          Enter email associated with your account and we will send an email
          with instructions to reset your password
        </p>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            setEmail(values.email)
            resetPasswordUser({
              email: values.email
            })
              .then(() => {
                setModalShow(true)
                setErrors(null)
              })
              .catch((err) => {
                console.log('error', err.data)
                setErrors(toFormErrors(err))
              })
            /* history.push('/passwordReset/2'); */
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
            // isSubmitting,
          }) => (
            <Form>
              <div className={classes.formFieldContainer}>
                <FormField
                  label="Email"
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                />
                {touched.email && errors.email
                  ? (
                  <div className={classes.errorMessageContainer}>
                    <ErrorMessage message={errors.email} />
                  </div>
                    )
                  : null}
              </div>

              {error && <FormErrorMessage errors={error} />}
              <div className={classes.buttonContainer}>
                <FormButton
                  label={isMobile ? 'Send instructions' : 'Send recovery email'}
                  onClick={handleSubmit}
                  style={{ marginBottom: 15 }}
                />
                <FormButton
                  label="Home"
                  onClick={() => history.push('/')}
                  subtle
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ModalLayout show={modalShow} onHide={() => setModalShow(false)}>
        <InformationSendEmail email={email} />
      </ModalLayout>
    </div>
  )
}

export default connect(null, {
  resetPasswordUser: authActions.resetPasswordUser
})(PasswordResetPage)
