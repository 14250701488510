import React from 'react'

import Form from 'react-bootstrap/Form'
import MaskInput from 'react-maskinput'

import classes from './styles.module.scss'

export default function FormField ({
  label,
  type,
  placeholder,
  value,
  onChange,
  readOnly,
  onBlur
}) {
  return (
    <Form.Group controlId={`formBasic${label}`} className={classes.FormField}>
      <Form.Label className={classes.formLabel}>{label}</Form.Label>
      <MaskInput
        maskChar=" "
        mask="0000 0000 0000 0000"
        className={classes.formControl}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
      />
    </Form.Group>
  )
}
