import axios from 'axios'
import { fetch } from 'whatwg-fetch'

import { getAuthData, saveAuthData, clearAuthData } from './authStorage'
import { API_URL } from '../constants/main'

axios.defaults.baseURL = API_URL

axios.interceptors.request.use(
  (config) => {
    const customConfig = { ...config }
    const { accessToken } = getAuthData()
    if (accessToken) {
      customConfig.headers.Authorization = `${accessToken}`
    }
    // config.headers['Content-Type'] = 'application/json';
    return customConfig
  },
  (error) => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const { refreshToken, userId } = getAuthData()
      if (!refreshToken) {
        return Promise.reject(error)
      }
      return fetch(`${API_URL}/auth-token-refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refreshToken,
          userId
        })
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.accessToken) {
            saveAuthData({
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
              userId: res.userId
            })
            axios.defaults.headers.common.Authorization = `${
              getAuthData().accessToken
            }`
            return axios(originalRequest)
          }
          return Promise.reject(res)
        })
        .catch((refreshTokenResponse) => {
          clearAuthData()

          document.location.href = '/login'
          return Promise.reject(refreshTokenResponse)
        })
    }
    return Promise.reject(error)
  }
)
