import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'

import * as invoicesActions from '../../../redux/actions/invoice'

import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'
import Title from '../../../components/Account/Title'
import CompanyCardGroup from '../../../components/Dashboard/CompanyCardGroup'
import FormButton from '../../../components/Form/StyledButton'
import Spinner from '../../../components/Spinner'

function ContractsGroups ({
  contractsToPay,
  getInvoices,
  // customer,
  invoices,
  user,
  invoicesToPay,
  isFetching
}) {
  const history = useHistory()

  useEffect(() => {
    if (!invoicesToPay.length) {
      getInvoices()
    }
  }, [])

  useEffect(() => {
    console.log(invoicesToPay)
  }, [invoicesToPay])

  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.ContractsGroups}>
          <div className={classes.col}>
            <Card className={classes.boxCompanyGroupList}>
              <Card.Body className={classes.boxCompanyListBody}>
                <div className={classes.title}>
                  <Title text={contractsToPay.companyTitle} />
                </div>
                {isFetching
                  ? (
                  <div className={classes.spinnerContainer}>
                    <Spinner />
                  </div>
                    )
                  : (
                  <div className={classes.companyGroups}>
                    {contractsToPay.contracts.map((contract) => {
                      return (
                        <div
                          className={classes.groupItem}
                          key={contract.CONTRATO}
                        >
                          <div
                            className={classes.contact}
                            key={contract.CONTRATO}
                          >
                            <span className={classes.label}>Contract: </span>
                            <span className={classes.value}>
                              {contract.CONTRATO}
                            </span>
                          </div>
                          {invoices.map((invoice) => {
                            return invoice.CONTRATO === contract.CONTRATO
                              ? (
                              <CompanyCardGroup
                                invoice={invoice}
                                user={user}
                                key={invoice.FACTURA}
                              />
                                )
                              : null
                          })}
                        </div>
                      )
                    })}
                  </div>
                    )}
              </Card.Body>
            </Card>
            <div className={classes.btnsContainer}>
              <div className={classes.btnPayNow}>
                <FormButton
                  secondary
                  label="Cancel"
                  onClick={() => history.push('/dashboard/contracts')}
                />
              </div>
              <div className={classes.btnPayNow}>
                <FormButton
                  label="Pay now"
                  onClick={() => history.push('/checkout/paymentAmount')}
                  disabled={!invoicesToPay.length}
                />
              </div>
            </div>
          </div>
        </div>
      </AppContainerWithMenu>
    </>
  )
}

export default connect(
  (state) => ({
    contractsToPay: state.contracts.contractsToPay,
    customer: state.customers.customer,
    user: state.users.user,
    invoices: state.invoices.invoices,
    isFetching: state.invoices.isFetching,
    invoicesToPay: state.invoices.invoicesToPay
  }),
  {
    getInvoices: invoicesActions.getInvoices
  }
)(ContractsGroups)
