import { GET_CARDS_SUCCESS, FETCHING, REMOVE_CARD_SUCCESS } from '../types'

const initialState = {
  cards: null,
  isFetching: false
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_CARDS_SUCCESS:
      return {
        ...state,
        cards: payload
      }
    case FETCHING:
      return {
        ...state,
        isFetching: payload
      }
    case REMOVE_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== payload)
      }
    default:
      return state
  }
}
