import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'

import MobileHeader from '../../../components/MobileHeader'
import FormField from '../../../components/Form/FormFieldWithInnerLabel'
import FormButton from '../../../components/Form/StyledButton'
import CardNumberField from '../../../components/Form/CardNumberFieldWithInnerLabel'
import ExpCardDateField from '../../../components/Form/ExpCardDateFieldWithInnerLabel'

import classes from './styles.module.scss'

export default function CreditCardRegistrationPage2 () {
  const [name, setName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [exDate, setExDate] = useState('')
  const [cvv, setCvv] = useState('')

  const history = useHistory()

  return (
    <div className={classes.CreditCardRegistrationPage2}>
      <MobileHeader />
      <div className={classes.backgroundContainer} />
      <div className={classes.contentContainer}>
        <h1 className={classes.title}>Credit Card Registration</h1>
        <Card className={classes.contentCard}>
          <Card.Body className={classes.cardBody}>
            <Card.Title className={classes.cardTitle}>
              Payment Details
            </Card.Title>
            <Form>
              <div className={classes.formRowContainer}>
                <FormField
                  label="Cardholder Name"
                  placeholder="Cardholder Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
              <div className={classes.formRowContainer}>
                <CardNumberField
                  label="Card Number"
                  placeholder="Card Number"
                  value={cardNumber}
                  onChange={(event) => setCardNumber(event.target.value)}
                />
                {/* <button type="button" className={classes.scanButton}>
                  Scan
                </button> */}
              </div>
              <div className={classes.formRowContainer}>
                <div className={classes.formFieldContainer}>
                  <ExpCardDateField
                    label="Ex. Date"
                    placeholder="Ex. Date"
                    value={exDate}
                    onChange={(event) => setExDate(event.target.value)}
                  />
                </div>
                <div className={classes.formFieldContainer}>
                  <FormField
                    label="CVV"
                    placeholder="CVV"
                    value={cvv}
                    onChange={(event) => setCvv(event.target.value)}
                  />
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <div className={classes.buttonContainer}>
          <FormButton
            label="Continue"
            onClick={() => history.push('/signup/creditCardRegistration3')}
          />
        </div>
      </div>
    </div>
  )
}
