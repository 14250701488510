import React, { useState, useEffect } from 'react'

import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
/* import FormControl from 'react-bootstrap/FormControl'; */

import classes from './styles.module.scss'

const documents = ['Passport', 'Identity ID', 'Other']

export default function DocumentTypeField ({
  label,
  /*   type,
  placeholder,
  readOnly,
  onBlur, */
  setFieldValue,
  name,
  value
}) {
  const [document, setDocument] = useState('Identity ID')
  useEffect(() => {
    setFieldValue(name, `${document}`)
  }, [document])
  useEffect(() => {
    if (value) {
      setDocument(value)
    }
  }, [])
  return (
    <InputGroup className={classes.DocumentTypeField}>
      <span className={classes.label}>{label}</span>
      <DropdownButton
        className={classes.dropDown}
        as={InputGroup.Prepend}
        variant="outline-secondary"
        title={document}
        id="input-group-dropdown-1"
      >
        {documents.map((item) => (
          <Dropdown.Item
            key={item}
            onClick={() => {
              setDocument(item)
            }}
          >
            {item}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </InputGroup>
  )
}
