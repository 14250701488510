import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_PASSWORD,
  LOGOUT,
  FETCHING,
  UPDATE_HAS_CLIENT_CODE
} from '../types'
import { getAuthData, getTokenData } from '../../helpers/authStorage'

const dataAuth = getAuthData()

const userIsLogged = dataAuth.accessToken
  ? { isLoggedIn: true, dataAuth }
  : { isLoggedIn: false, dataAuth: null }

const isCustomer = getTokenData()?.role === 'customer'
const hasClientCode = getTokenData()?.hasClientCode

const initialState = {
  ...userIsLogged,
  resetPassword: null,
  isFetching: false,
  isCustomer,
  hasClientCode
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case FETCHING:
      return {
        ...state,
        isFetching: payload
      }
    case LOGIN_SUCCESS:
      console.log(payload.dataAuth)

      return {
        ...state,
        isLoggedIn: true,
        isCustomer: payload.dataAuth.role === 'customer',
        dataAuth: payload.dataAuth,
        hasClientCode: payload.dataAuth.hasClientCode
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        dataAuth: null
      }
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: payload.data
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        dataAuth: null
      }
    case UPDATE_HAS_CLIENT_CODE:
      return {
        ...state,
        hasClientCode: true
      }
    default:
      return state
  }
}
