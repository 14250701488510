import React from 'react'

import { useMediaQuery } from 'react-responsive'

import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'

import classes from './styles.module.scss'

export default function AppContainerWithArcBackgraund ({ children }) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  return (
    <div className={classes.AppContainerWithArcBackgraund}>
      <div className={classes.ArcTopBackground} />
      {isSmallScreen ? <MobileHeader /> : <Header blueClip />}
      {children}
    </div>
  )
}
