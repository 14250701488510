import { GET_USER_SUCCESS, FETCHING, UPDATING } from '../types'

import UserService from '../../services/user.service'

export const getUser = (id) => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return UserService.getUser(id)
    .then(({ data }) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: data
      })
      dispatch({
        type: FETCHING,
        payload: false
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: FETCHING,
        payload: false
      })
      return Promise.reject(error.response)
    })
}

export const patchUser = (values) => (dispatch) => {
  console.log('HALLO?')
  dispatch({
    type: UPDATING,
    payload: true
  })
  return UserService.patchUser(values)
    .then(({ data }) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: data
      })
      dispatch({
        type: UPDATING,
        payload: false
      })
      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: UPDATING,
        payload: false
      })
      return Promise.reject(error.response)
    })
}

export const changePasswordUser = (values) => () => {
  return UserService.changePasswordUser(values)
    .then(() => {
      return Promise.resolve()
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}
