import { IS_CHATBOT } from '../types'

const initialState = {
  isChatbot: false
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case IS_CHATBOT:
      return {
        ...state,
        isChatbot: payload
      }
    default:
      return state
  }
}
