import axios from 'axios'

const registerCustomer = (values) => {
  return axios.post('/users/register-customer', {
    ...values
  })
}

const getCustomer = () => {
  return axios.get('/users/customers')
}

const patchCustomer = (values) => {
  return axios.patch('/users', { ...values })
}

export default {
  registerCustomer,
  getCustomer,
  patchCustomer
}
