import {
  IS_CHATBOT
} from '../types'

export const setChatbot = () => (dispatch) => {
  dispatch({
    type: IS_CHATBOT,
    payload: true
  })
}
