import axios from 'axios'

const getUser = () => {
  return axios.get('/users/me')
}
const patchUser = (values) => {
  return axios.patch('/users', { ...values })
}
const changePasswordUser = (values) => {
  return axios.post('/change-password', { ...values })
}

export default {
  getUser,
  patchUser,
  changePasswordUser
}
