import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { PaymentContext } from '../context'

import HomePage from '../pages/HomePage/index'
import SignUpPage from '../pages/SignUp/SignUpPage'
import SignUpMobilePage from '../pages/SignUp/SignUpMobilePage'
import EmailConfirmationPage from '../pages/SignUp/EmailConfirmationPage'
// import EmailConfirmationMobilePage from '../pages/SignUp/EmailConfirmationMobilePage';
import RegistrationPage from '../pages/SignUp/RegistrationPage'
// import RegistrationMobilePage from '../pages/SignUp/RegistrationMobilePage'
import CreditCardRegistrationPage from '../pages/SignUp/CreditCardRegistrationPage'
import CreditCardRegistrationPage2 from '../pages/SignUp/CreditCardRegistrationPage2'
import CreditCardRegistrationPage3 from '../pages/SignUp/CreditCardRegistrationPage3'
import ClientCodePage from '../pages/SignUp/ClientCodePage'
import CreateNewPassword from '../pages/SignUp/CreateNewPasswordPage'
import LoginPage from '../pages/Login/LoginPage'
import PasswordResetPage from '../pages/Login/PasswordResetPage'
import PasswordResetPage2 from '../pages/Login/PasswordResetPage2'
import PasswordResetPage3 from '../pages/Login/PasswordResetPage3'
import PaymentConfirmationPage from '../pages/Plus/PaymentConfirmationPage'
import PaymentUnsuccessfulPage from '../pages/Plus/PaymentUnsuccessfullPage'
import PrintPage from '../pages/Plus/PrintPage'
import ProfilePage from '../pages/Account/Profile'
import EditProfilePage from '../pages/Account/EditProfile'
import WalletPage from '../pages/Account/Wallet'
import ContractsPage from '../pages/Dashboard/Contracts'
import ContractsListPage from '../pages/Dashboard/ContractsList'
import ContractsGroupsPage from '../pages/Dashboard/ContractsGroups'
import HistoryPage from '../pages/TransationHistory/TransationHistoryPage'
import CreditCardRegistrationTransationPage from '../pages/TransationHistory/CreditCardRegistrationPage'
import PaymentDetailsPage from '../pages/CheckOut/PaymentDetails'
import PaymentConfirmationPageSuccess from '../pages/CheckOut/PaymentConfirmationPageSuccess'
import PaymentAmountPage from '../pages/CheckOut/PaymentAmount'
import CheckOutPage from '../pages/CheckOut/CheckOutPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import InvoicePage from '../pages/TransationHistory/InvoicePage'
import TermsOfUsePage from '../pages/TermsOfUsePage'
import LoginSessionPage from '../pages/Login/LoginSessionPage'

/* eslint-disable react/prop-types */
const Routes = ({ isAuth, isCustomer, hasClientCode }) => {
  const [orderNumber, setOrderNumber] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [cardHolderName, setCardHolderName] = useState('')
  const [expDate, setExpDate] = useState('')
  const [cvv, setCvv] = useState('')
  const [cardId, setCardId] = useState('')
  const [processedInvoices, setProcessedInvoices] = useState([])
  const [shouldCardBeSaved, setShouldCardBeSaved] = useState(false)
  const [address, setAddress] = useState('')
  const [postCode, setPostCode] = useState('')
  const [country, setCountry] = useState('DO')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [shouldSaveAddress, setShouldSaveAddress] = useState(false)

  useEffect(() => {
    document.body.style.fontFamily = '\'Lato\', sans-serif'
  }, [])

  const isMobile = useMediaQuery({ query: '(max-width: 570px)' })

  if (isAuth) {
    return (
      <BrowserRouter>
        <Switch>
          <PaymentContext.Provider
            value={{
              orderNumber,
              setOrderNumber,
              cardNumber,
              setCardNumber,
              cardHolderName,
              setCardHolderName,
              expDate,
              setExpDate,
              cvv,
              setCvv,
              cardId,
              setCardId,
              processedInvoices,
              setProcessedInvoices,
              shouldCardBeSaved,
              setShouldCardBeSaved,
              address,
              setAddress,
              postCode,
              setPostCode,
              country,
              setCountry,
              state,
              setState,
              city,
              setCity,
              shouldSaveAddress,
              setShouldSaveAddress
            }}
          >
            {!isCustomer && (
              <Route
                path="/signup/registration"
                exact
                // component={isMobile ? RegistrationMobilePage : RegistrationPage}
                component={RegistrationPage}
              />
            )}
            {!hasClientCode && (
              <Route
                path="/signup/clientCode"
                exact
                // component={
                //   isMobile ? EmailConfirmationMobilePage : ClientCodePage
                // }
                component={ClientCodePage}
              />
            )}
            {hasClientCode && (
              <>
                <Route
                  path="/signup/clientCode"
                  exact
                  // component={
                  //   isMobile ? EmailConfirmationMobilePage : ClientCodePage
                  // }
                  component={ClientCodePage}
                />
                <Route path="/" exact component={ContractsPage} />
                <Route
                  path="/signup/creditCardRegistration"
                  exact
                  component={CreditCardRegistrationPage}
                />
                <Route
                  path="/signup/creditCardRegistration2"
                  exact
                  component={CreditCardRegistrationPage2}
                />
                <Route
                  path="/signup/creditCardRegistration3"
                  exact
                  component={CreditCardRegistrationPage3}
                />
                <Route
                  path="/plus/paymentConfirmation"
                  exact
                  component={PaymentConfirmationPage}
                />
                <Route
                  path="/plus/paymentUnsuccessful"
                  exact
                  component={PaymentUnsuccessfulPage}
                />
                <Route path="/plus/print" exact component={PrintPage} />
                <Route path="/account/profile" exact component={ProfilePage} />
                <Route
                  path="/account/profile/edit"
                  exact
                  component={EditProfilePage}
                />
                <Route path="/account/wallet" exact component={WalletPage} />
                <Route
                  path="/dashboard/contracts"
                  exact
                  component={ContractsPage}
                />
                <Route
                  path="/dashboard/contracts/list"
                  exact
                  component={ContractsListPage}
                />
                <Route
                  path="/dashboard/contracts/group"
                  exact
                  component={ContractsGroupsPage}
                />
                <Route path="/history" exact component={HistoryPage} />
                <Route
                  path="/history/creditCardRegistration"
                  exact
                  component={CreditCardRegistrationTransationPage}
                />
                <Route
                  path="/checkout/paymentDetails"
                  exact
                  component={PaymentDetailsPage}
                />
                <Route
                  path="/checkout/paymentConfirmation"
                  exact
                  component={PaymentConfirmationPageSuccess}
                />
                <Route
                  path="/checkout/paymentAmount"
                  exact
                  component={PaymentAmountPage}
                />
                <Route
                  path="/checkout/checkOut"
                  exact
                  component={CheckOutPage}
                />
                <Route path="/invoice" exact component={InvoicePage} />
              </>
            )}
            {!isCustomer && <Redirect to="/signup/registration" />}
            {!hasClientCode && isCustomer && (
              <Redirect to="/signup/clientCode" />
            )}
          </PaymentContext.Provider>
        </Switch>
      </BrowserRouter>
    )
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route
          path="/signup"
          exact
          component={isMobile ? SignUpMobilePage : SignUpPage}
        />
        <Route
          path="/signup/confirmEmail"
          exact
          component={EmailConfirmationPage}
        />
        <Route
          path="/signup/createNewPassword"
          exact
          component={CreateNewPassword}
        />
        <Route path="/healthz">
          <pre>Ok</pre>
        </Route>

        <Route path="/login" exact component={LoginPage} />
        <Route path="/passwordReset" exact component={PasswordResetPage} />
        <Route path="/passwordReset/2" exact component={PasswordResetPage2} />
        <Route path="/passwordReset/3" exact component={PasswordResetPage3} />
        <Route path="/reset-password" exact component={ResetPasswordPage} />
        <Route path="/terms-of-use" exact component={TermsOfUsePage} />
        <Route path="/chatbot-payment-link/:sessionID" component={LoginSessionPage} />
        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  )
}

export default connect((state) => ({
  isAuth: state.auth.isLoggedIn,
  isCustomer: state.auth.isCustomer,
  hasClientCode: state.auth.hasClientCode
}))(Routes)
