import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import authActions from '../../../redux/actions/auth'
import * as contractsActions from '../../../redux/actions/contract'
import * as usersActions from '../../../redux/actions/user'
import * as customersActions from '../../../redux/actions/customer'
import * as invoicesActions from '../../../redux/actions/invoice'
import * as chatbotActions from '../../../redux/actions/chatbot'

import { connect } from 'react-redux'
import { forEach } from 'lodash'

const LoginSessionPage = ({ loginUserSession, getUser, getCustomer, getContracts, getInvoices, setContractToPayCompanyName, addContractToPay, addInvoiceToPay, setChatbot, history }) => {
  const { pathname } = useLocation()
  const { sessionID } = useParams()
  useEffect(() => {
    if (sessionID) {
      loginUserSession({ sessionID, pathname }).then(
        (res) => {
          if (!res.data) {
            return history.push('/signup/registration')
          }
          if (!res.data.clientCode) {
            return history.push('/signup/clientCode')
          }

          const sessionData = JSON.parse(res.data.sessionData)
          const company = sessionData?.invoices[0]?.SOCIEDAD
          setChatbot()
          if (company) {
            fecthFunction(sessionData.invoices, company)
          } else {
            return history.push('/dashboard/contracts')
          }
        },
        (err) => {
          console.error(err)
        }
      )
    }
  }, [sessionID, pathname])

  const fecthFunction = (invoices, company) => {
    getUser()
    getCustomer()
    getContracts()
    getInvoices()
    setContractToPayCompanyName(company)
    addContractToPay(invoices[0])
    forEach(invoices, (item) => {
      addInvoiceToPay(item)
    })
    setTimeout(() => {
      history.push('/dashboard/contracts')
      history.push('/dashboard/contracts/group')
      setTimeout(() => {
        history.push('/checkout/paymentAmount')
      }, 100)
    }, 100)
  }
  return (
    <div>
      {/* <h1>Token Route</h1> */}
      {/* Puedes agregar más contenido al componente si lo deseas */}
    </div>
  )
}
export default connect(
  (state) => ({
    contractsToPay: state.contracts.contractsToPay,
    contracts: state.contracts.contracts,
    user: state.users.user,
    customer: state.customers.customer
  }), {
    loginUserSession: authActions.loginUserSession,
    getUser: usersActions.getUser,
    getCustomer: customersActions.getCustomer,
    getContracts: contractsActions.getContracts,
    setContractToPayCompanyName: contractsActions.setContractsToPayCompanyName,
    addContractToPay: contractsActions.addContractToPay,
    getInvoices: invoicesActions.getInvoices,
    addInvoiceToPay: invoicesActions.addInvoiceToPay,
    setChatbot: chatbotActions.setChatbot
  })(LoginSessionPage)
