import React from 'react'

import PhoneInput from 'react-phone-number-input'
import InputGroup from 'react-bootstrap/InputGroup'

import 'react-phone-number-input/style.css'
import classes from './styles.module.scss'

export default function PhoneField (props) {
  const {
    field: { name, value },
    form: { handleBlur, setFieldValue },
    label,
    onChange
  } = props

  const onValueChange = (phoneNumber = '') => {
    setFieldValue(name, phoneNumber)

    if (onChange !== null) {
      onChange(phoneNumber)
    }
  }

  const handleInputBlur = (e) => {
    handleBlur(e)
  }

  return (
    <InputGroup className={classes.PhoneField}>
      <span className={classes.label}>{label}</span>
      <PhoneInput
        placeholder="Enter phone number"
        name={name}
        value={value}
        onChange={onValueChange}
        onBlur={handleInputBlur}
        countrySelectProps={{ unicodeFlags: true }}
        className={classes.phoneContainer}
        defaultCountry="DO"
      />
    </InputGroup>
  )
}
