import React from 'react'

import Card from 'react-bootstrap/Card'

import MobileHeader from '../../../components/MobileHeader'
import Button from '../../../components/Form/StyledButton'

import classes from './styles.module.scss'

export default function CreditCardRegistrationPage () {
  return (
    <div className={classes.CreditCardRegistrationPage}>
      <MobileHeader />
      <div className={classes.contentContainer}>
        <h1 className={classes.title}>Credit Card Registration</h1>
        <Card className={classes.contentCard}>
          <Card.Body className={classes.cardBody}>
            <div className={classes.scaner} />
            <p className={classes.details}>
              Click here to scan your credit card.
            </p>
            <Button label="Scan" />
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
