import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { useMediaQuery } from 'react-responsive'

import { connect } from 'react-redux'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Card, Form } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

import classNames from 'classnames'
import * as usersActions from '../../../redux/actions/user'
import * as customerActions from '../../../redux/actions/customer'

import toFormErrors from '../../../helpers/toFormErrors'
import FormErrorMessage from '../../../components/Messages/ErrorMessage'
import EmptyHeader from '../../../components/EmptyHeader'
import FormField from '../../../components/Form/FormField'
import PhoneField from '../../../components/Form/PhoneField'
// import CountryField from '../../../components/Form/CountryField';
import DocumentTypeField from '../../../components/Form/DocumentTypeField'
import FormButton from '../../../components/Form/StyledButton'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import forceTokenRefresh from '../../../helpers/forceTokenRefresh'

import { API_URL } from '../../../constants/main'
import classes from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom'
import CheckBox from '../../../components/Form/CheckBox'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '+1',
  address: '',
  city: '',
  state: '',
  country: '',
  postCode: '',
  docType: '',
  documentId: '',
  agree: false,
  phoneConfirmationCode: ''
}

const initialValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your name'),
  lastName: Yup.string().required('Please enter your last name'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  docType: Yup.string().required('Please enter document type'),
  documentId: Yup.string().required('Please enter document ID')
})

const validationPhone = (value) => {
  let errorMessage
  if (!isValidPhoneNumber(value)) {
    errorMessage = value ? 'Invalid phone number' : 'Please enter your phone'
  }
  return errorMessage
}

let timer

function RegistrationPage ({
  user,
  getUser,
  isFetching,
  registerCustomer,
  history
}) {
  const [error, setErrors] = useState(null)
  const [isConfirmationSMSSent, setIsConfirmationSMSSent] = useState(false)
  const [
    isPhoneConfirmationCodeCorrect,
    setIsPhoneConfirmationCodeCorrect
  ] = useState(false)
  const [validationSchema, setValidationSchema] = useState(
    initialValidationSchema
  )
  const query = new URLSearchParams(useLocation().search)
  const isChatbot = query.get('isChatbot')
  const isMobile = useMediaQuery({ query: '(max-width: 570px)' })

  useEffect(() => {
    if (isChatbot) {
      initialValues.password = ''
      initialValues.passwordConfirmation = ''
      initialValues.agree = false

      setValidationSchema(
        Yup.object().shape({
          ...initialValidationSchema.fields,
          password: Yup.string().min(2, 'Too Short!').required('Please enter password'),
          passwordConfirmation: Yup.string()
            .min(2, 'Too Short!')
            .required('Please confirm your password')
            .oneOf([Yup.ref('password'), null], 'Password mismatch'),
          agree: Yup.bool().oneOf(
            [true],
            "You can't continue without agreeing to terms of use"
          )
        }))
    }
  }, [useLocation().search])

  useEffect(() => {
    const phoneSelectorArrow = document.querySelector(
      '.PhoneInputCountrySelectArrow'
    )
    if (phoneSelectorArrow) {
      phoneSelectorArrow.style.opacity = 1
      phoneSelectorArrow.style.borderColor = 'black'
    }
  })

  const requestPhoneConfirmationCode = async (phone) => {
    try {
      await axios.post(`${API_URL}/users/request-phone-confirmation`, {
        phone
      })
      setIsConfirmationSMSSent(true)
    } catch (err) {
      console.log(err)
    }
  }

  const confirmPhone = async (phoneConfirmationCode, setTouched, touched) => {
    try {
      if (phoneConfirmationCode.trim().length !== 4) {
        return
      }
      await axios.post(`${API_URL}/users/confirm-phone`, {
        phoneConfirmationCode
      })
      setIsPhoneConfirmationCodeCorrect(true)
      setIsConfirmationSMSSent(true)
      setTouched({ ...touched, phoneConfirmationCode: true })
    } catch (err) {
      console.log(err)
      setIsPhoneConfirmationCodeCorrect(false)
      setTouched({ ...touched, phoneConfirmationCode: true })
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    initialValues.email = user?.email || ''
    initialValues.phone = user?.phone || '+1'
  }, [user])

  const formikTemplate = <>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          const params = { ...values, isChatbot }
          delete params.email
          await registerCustomer({
            ...params
          })
          await forceTokenRefresh()
          history.push('/signup/clientCode')
        } catch (e) {
          setErrors(toFormErrors(e))
        }
      }}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setTouched
        // isSubmitting,
      }) => (
        <Form className={classes.form} noValidate>
          <div className={classes.formFieldContainer}>
            <FormField
              label="First Name"
              type="text"
              placeholder="Enter name"
              onChange={handleChange('firstName')}
              onBlur={handleBlur('firstName')}
            />
            {touched.firstName && errors.firstName
              ? (
              <ErrorMessage message={errors.firstName} />
                )
              : null}
          </div>
          <div className={classes.formFieldContainer}>
            <FormField
              label="Last Name"
              type="text"
              placeholder="Enter last name"
              onChange={handleChange('lastName')}
              onBlur={handleBlur('lastName')}
            />
            {touched.lastName && errors.lastName
              ? (
              <ErrorMessage message={errors.lastName} />
                )
              : null}
          </div>
          <div
            className={classNames(
              classes.formFieldContainer,
              isPhoneConfirmationCodeCorrect && classes.isValid
            )}
          >
            <Field
              label="Phone number"
              type="text"
              name="phone"
              onChange={handleChange('phone')}
              onBlur={handleBlur('phone')}
              component={PhoneField}
              validate={validationPhone}
            />
            {touched.phone && errors.phone
              ? (
              <ErrorMessage message={errors.phone} />
                )
              : null}
          </div>
          {touched.phone &&
            !errors.phone &&
            !isPhoneConfirmationCodeCorrect && (
              <div className={classes.formFieldContainer}>
                <FormButton
                  disabled={isConfirmationSMSSent}
                  label="Validate phone number"
                  secondary
                  onClick={() =>
                    requestPhoneConfirmationCode(values.phone)
                  }
                />
              </div>
          )}
          {isConfirmationSMSSent && !isPhoneConfirmationCodeCorrect && (
            <div className={classes.formFieldContainer}>
              <FormField
                label="Phone Validation Code"
                type="text"
                placeholder="Enter phone validation code"
                onChange={handleChange('phoneConfirmationCode')}
                onBlur={handleBlur('phoneConfirmationCode')}
                onKeyUp={() => {
                  clearTimeout(timer)
                  timer = setTimeout(() => {
                    confirmPhone(
                      values.phoneConfirmationCode,
                      setTouched,
                      touched
                    )
                  }, 200)
                }}
              />
              {touched.phoneConfirmationCode &&
              !isPhoneConfirmationCodeCorrect
                ? (
                <ErrorMessage message="Invalid code" />
                  )
                : null}
            </div>
          )}
          <div className={classes.formFieldContainer}>
            <FormField
              label="Email Confirmation"
              type="email"
              placeholder="Enter email"
              value={user.email}
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
            />
            {touched.email && errors.email
              ? (
              <ErrorMessage message={errors.email} />
                )
              : null}
          </div>
          <div className={classes.formFieldContainer}>
            <DocumentTypeField
              label="Document type"
              name="docType"
              type="text"
              placeholder="Enter document type"
              value={values.docType}
              setFieldValue={setFieldValue}
              onBlur={handleBlur('docType')}
            />
            {touched.docType && errors.docType
              ? (
              <ErrorMessage
                message={
                  values.docType === ''
                    ? 'Please enter your document type'
                    : errors.docType
                }
              />
                )
              : null}
          </div>
          <div
            className={classes.formFieldContainer}
          >
            <FormField
              label="Document ID"
              type="text"
              placeholder="Enter document ID"
              value={values.documentId}
              onChange={handleChange('documentId')}
              onBlur={handleBlur('documentId')}
            />
            {touched.documentId && errors.documentId
              ? (
              <ErrorMessage message={errors.documentId} />
                )
              : null}
          </div>

          {isChatbot && (<>
            <div className={classes.formFieldContainer}>
                <FormField
                  label="Password"
                  type="password"
                  placeholder="Enter password"
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  value={values.password} />
                {touched.password && errors.password
                  ? (
                  <ErrorMessage message={errors.password} />
                    )
                  : null}
              </div>
              <div className={classes.formFieldContainer}>
                  <FormField
                    label="Re-enter password"
                    type="password"
                    placeholder="Confirm password"
                    onChange={handleChange('passwordConfirmation')}
                    onBlur={handleBlur('passwordConfirmation')}
                    value={values.passwordConfirmation} />
                  {touched.passwordConfirmation &&
                    errors.passwordConfirmation
                    ? (
                    <ErrorMessage message={errors.passwordConfirmation} />
                      )
                    : null}
                </div>
                <div className={classes.formFieldContainer}>
                  <CheckBox
                    label="&nbsp;"
                    checked={values.agree}
                    name="agree"
                    onChange={handleChange('agree')} />
                    Agree to <Link to="/terms-of-use">Terms of Use</Link>
                  {touched.agree && errors.agree
                    ? (
                    <ErrorMessage message={errors.agree} />
                      )
                    : null}
                </div>
              </>
          )}
          {error && <FormErrorMessage errors={error} />}
          <div className={classes.formFieldContainer}>
            <FormButton label="Continue" onClick={handleSubmit} />
          </div>
        </Form>
      )}
    </Formik>
  </>

  return (
    <div className={classes.RegistrationPage}>
      <EmptyHeader />
      <div className={classes.backgroundContainer} />
       {isMobile && (
          <>
            <h1 className={classes.formTitle}>Registration Mobile</h1>
            <p className={classes.details}>Personal info form</p>
            {!isFetching && user && (
              formikTemplate
            )}
          </>
       )}
        {!isMobile && (
          <Card className={classes.registrationForm}>
            <Card.Body className={classes.registrationFormBody}>
              <Card.Title className={classes.formTitle}>Registration</Card.Title>
              <p className={classes.details}>Personal info form</p>
                {!isFetching && user && (
                  formikTemplate
                )}
            </Card.Body>
          </Card>
        )}
    </div>
  )
}

export default connect(
  (state) => ({
    isFetching: state.users.isFetching,
    user: state.users.user
  }),
  {
    getUser: usersActions.getUser,
    patchUser: usersActions.patchUser,
    registerCustomer: customerActions.registerCustomer
  }
)(RegistrationPage)
