import React, { useEffect, useState } from 'react'

import { Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Title from '../../../components/Account/Title'
import CardItem from '../../../components/Account/Card'
import ContainerWithArcBackgraund from '../../../containers/layouts/ContainerWithArcBackgraund'
import classes from './styles.module.scss'
import ModalLayout from '../../../modals/ModalLayout'
import ConfirmDeleteCreditCard from '../../../modals/ConfirmDeleteCreditCard'
import FormButton from '../../../components/Form/StyledButton'
import * as actions from '../../../redux/actions/card'

export function Wallet ({ getCards, cards, deleteCard }) {
  const [modalShow, setModalShow] = React.useState(false)
  const [selectedCardId, setSelectedCardId] = useState(null)

  const history = useHistory()

  const onDelete = (cardId) => {
    setModalShow(true)
    setSelectedCardId(cardId)
  }

  const onConfirmDelete = () => {
    deleteCard(selectedCardId)
  }

  useEffect(() => {
    getCards()
  }, [])

  return (
    <>
      <ContainerWithArcBackgraund>
        <div className={classes.Wallet}>
          <Card className={classes.boxWallet}>
            <Card.Body className={classes.boxWalletBody}>
              <div className={classes.title}>
                <Title text="Wallet" />
              </div>
              <div className={classes.wrapper}>
                {cards
                  ? cards.map((card) => (
                      <CardItem
                        onDelete={() => onDelete(card.id)}
                        key={card.id}
                        card={card}
                      />
                  ))
                  : null}
              </div>
              <div
                className={classes.addCard}
                onClick={() => {
                  history.push({
                    pathname: '/signup/creditCardRegistration',
                    search: '?default=false'
                  })
                }}
              >
                <svg
                  width="31"
                  height="28"
                  viewBox="0 0 31 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 22V11L24.5 1V11.5L1 22Z" stroke="#666666" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28 11.5H10C9.60218 11.5 9.22064 11.658 8.93934 11.9393C8.65804 12.2206 8.5 12.6022 8.5 13V25C8.5 25.3978 8.65804 25.7794 8.93934 26.0607C9.22064 26.342 9.60218 26.5 10 26.5H28C28.3978 26.5 28.7794 26.342 29.0607 26.0607C29.342 25.7794 29.5 25.3978 29.5 25V13C29.5 12.6022 29.342 12.2206 29.0607 11.9393C28.7794 11.658 28.3978 11.5 28 11.5ZM10 10C9.20435 10 8.44129 10.3161 7.87868 10.8787C7.31607 11.4413 7 12.2044 7 13L7 25C7 25.7956 7.31607 26.5587 7.87868 27.1213C8.44129 27.6839 9.20435 28 10 28H28C28.7956 28 29.5587 27.6839 30.1213 27.1213C30.6839 26.5587 31 25.7956 31 25V13C31 12.2044 30.6839 11.4413 30.1213 10.8787C29.5587 10.3161 28.7956 10 28 10H10Z"
                    fill="#666666"
                  />
                  <path
                    d="M10 15.75C10 15.5511 10.0878 15.3603 10.2441 15.2197C10.4004 15.079 10.6123 15 10.8333 15H14.1667C14.3877 15 14.5996 15.079 14.7559 15.2197C14.9122 15.3603 15 15.5511 15 15.75V17.25C15 17.4489 14.9122 17.6397 14.7559 17.7803C14.5996 17.921 14.3877 18 14.1667 18H10.8333C10.6123 18 10.4004 17.921 10.2441 17.7803C10.0878 17.6397 10 17.4489 10 17.25V15.75Z"
                    fill="#666666"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 19.8333C10 19.6123 10.0814 19.4004 10.2263 19.2441C10.3712 19.0878 10.5678 19 10.7727 19H18.5C18.7049 19 18.9015 19.0878 19.0464 19.2441C19.1913 19.4004 19.2727 19.6123 19.2727 19.8333C19.2727 20.0543 19.1913 20.2663 19.0464 20.4226C18.9015 20.5789 18.7049 20.6667 18.5 20.6667H10.7727C10.5678 20.6667 10.3712 20.5789 10.2263 20.4226C10.0814 20.2663 10 20.0543 10 19.8333ZM10 23.1667C10 22.9457 10.0814 22.7337 10.2263 22.5774C10.3712 22.4211 10.5678 22.3333 10.7727 22.3333H12.3182C12.5231 22.3333 12.7197 22.4211 12.8646 22.5774C13.0095 22.7337 13.0909 22.9457 13.0909 23.1667C13.0909 23.3877 13.0095 23.5996 12.8646 23.7559C12.7197 23.9122 12.5231 24 12.3182 24H10.7727C10.5678 24 10.3712 23.9122 10.2263 23.7559C10.0814 23.5996 10 23.3877 10 23.1667ZM14.6364 23.1667C14.6364 22.9457 14.7178 22.7337 14.8627 22.5774C15.0076 22.4211 15.2042 22.3333 15.4091 22.3333H16.9545C17.1595 22.3333 17.356 22.4211 17.5009 22.5774C17.6459 22.7337 17.7273 22.9457 17.7273 23.1667C17.7273 23.3877 17.6459 23.5996 17.5009 23.7559C17.356 23.9122 17.1595 24 16.9545 24H15.4091C15.2042 24 15.0076 23.9122 14.8627 23.7559C14.7178 23.5996 14.6364 23.3877 14.6364 23.1667ZM19.2727 23.1667C19.2727 22.9457 19.3541 22.7337 19.4991 22.5774C19.644 22.4211 19.8405 22.3333 20.0455 22.3333H21.5909C21.7958 22.3333 21.9924 22.4211 22.1373 22.5774C22.2822 22.7337 22.3636 22.9457 22.3636 23.1667C22.3636 23.3877 22.2822 23.5996 22.1373 23.7559C21.9924 23.9122 21.7958 24 21.5909 24H20.0455C19.8405 24 19.644 23.9122 19.4991 23.7559C19.3541 23.5996 19.2727 23.3877 19.2727 23.1667ZM23.9091 23.1667C23.9091 22.9457 23.9905 22.7337 24.1354 22.5774C24.2803 22.4211 24.4769 22.3333 24.6818 22.3333H26.2273C26.4322 22.3333 26.6288 22.4211 26.7737 22.5774C26.9186 22.7337 27 22.9457 27 23.1667C27 23.3877 26.9186 23.5996 26.7737 23.7559C26.6288 23.9122 26.4322 24 26.2273 24H24.6818C24.4769 24 24.2803 23.9122 24.1354 23.7559C23.9905 23.5996 23.9091 23.3877 23.9091 23.1667Z"
                    fill="#666666"
                  />
                </svg>
                <span>Add a card/credit card</span>
              </div>
              <div className={classes.btnSave} style={{ display: 'none' }}>
                <FormButton label="Save" onClick={() => setModalShow(true)} />
              </div>
            </Card.Body>
          </Card>
          <div className={classes.BubblesBottomBackround} />
        </div>
      </ContainerWithArcBackgraund>
      <ModalLayout show={modalShow} onHide={() => setModalShow(false)}>
        <ConfirmDeleteCreditCard
          onHide={() => setModalShow(false)}
          onConfirmDelete={onConfirmDelete}
        />
      </ModalLayout>
    </>
  )
}

export default connect(
  (state) => ({
    cards: state.cards.cards
  }),
  {
    getCards: actions.getCards,
    deleteCard: actions.deleteCard
  }
)(Wallet)
