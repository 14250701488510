import React, { useState } from 'react'

import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import classnames from 'classnames'
import * as Yup from 'yup'
import { Formik } from 'formik'
import authActions from '../../../redux/actions/auth'

import EmptyHeaderWithLogo from '../../../components/EmptyHeaderWithLogo'
import FormField from '../../../components/Form/FormField'
import CheckBox from '../../../components/Form/CheckBox'
import FormButton from '../../../components/Form/StyledButton'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import FormErrorMessage from '../../../components/Messages/ErrorMessage'
import classes from './styles.module.scss'
import { saveUserData, getUserData } from '../../../helpers/rememberMe'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  password: Yup.string().required('Please enter your password')
})

function LoginPage ({ loginUser, history }) {
  const [error, setError] = useState(null)
  const { email, rememberMe } = getUserData()

  const initialValues = {
    email,
    password: '',
    rememberMe: !!rememberMe
  }

  const loginHandler = (values) => {
    saveUserData(values)
    loginUser({
      email: values.email,
      password: values.password
    }).then(
      (res) => {
        if (!res.data) {
          return history.push('/signup/registration')
        }
        if (!res.data.clientCode) {
          return history.push('/signup/clientCode')
        }
        return history.push('/dashboard/contracts')
      },
      (err) => {
        if (
          err.response.data.detail[0] ===
          'Your account has not been activated yet. Please check your email for further instructions'
        ) {
          history.push(`/signup/confirmEmail?email=${values.email}`)
        }

        setError(err.response.data.detail)
      }
    )
  }
  return (
    <div className={classes.LoginPage}>
      <EmptyHeaderWithLogo wavy />
      <div className={classes.contentContainer}>
        <h1 className={classes.title}>Log in</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={loginHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
            // isSubmitting,
          }) => (
            <Form className={classes.loginForm}>
              <div className={classes.formFieldContainer}>
                <FormField
                  label="Email"
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                />
                {touched.email && errors.email
                  ? (
                  <ErrorMessage message={errors.email} />
                    )
                  : null}
              </div>
              <div
                className={classnames(
                  classes.formFieldContainer,
                  classes.lastOne
                )}
              >
                <FormField
                  label="Password"
                  type="password"
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  value={values.password}
                />
                {touched.password && errors.password
                  ? (
                  <ErrorMessage message={errors.password} />
                    )
                  : null}
              </div>
              {error && <FormErrorMessage errors={error} />}
              <div className={classes.checkBoxContainer}>
                <CheckBox
                  label="Remember me?"
                  checked={values.rememberMe}
                  name="rememberMe"
                  onChange={handleChange('rememberMe')}
                />
                <NavLink to="/passwordReset" className={classes.forgotPassword}>
                  Forgot password?
                </NavLink>
              </div>
              <div className={classes.buttonContainer}>
                <FormButton
                  label="Log in"
                  onClick={handleSubmit}
                  style={{ marginBottom: 15 }}
                />
                <FormButton
                  label="Sign Up"
                  onClick={() => history.push('/signup')}
                  subtle
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default connect(null, {
  loginUser: authActions.loginUser
})(LoginPage)
