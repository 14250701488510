import React from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/images/logoVertical.svg'
import FormButton from '../../components/Form/StyledButton'
import classes from './styles.module.scss'

export default function HomePage () {
  const history = useHistory()
  return (
    <div className={classes.HomePage}>
      <div className={classes.HomePageWrap}>
        <div className={classes.logo}>
          <img src={logo} alt="Logo" />
        </div>
        <h1 className={classes.title}>Cap Cana</h1>
        <p className={classes.description}>
          Carrying out financial transactions with the best security
        </p>
        <div className={classes.btnWrapp}>
          <FormButton
            subtle
            label="Log in"
            grayBorder
            onClick={() => history.push('/login')}
          />
          <FormButton
            label="Sign up"
            white
            onClick={() => history.push('/signup')}
          />
        </div>
      </div>
    </div>
  )
}
