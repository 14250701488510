import React, { useState, useRef, useEffect } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import { useMediaQuery } from 'react-responsive'
import { connect } from 'react-redux'

import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'
import StyledButton from '../../../components/Form/StyledButton'
import authActions from '../../../redux/actions/auth'

import classes from './styles.module.scss'

export function EmailConfirmationPage ({ verifyEmail }) {
  const [confirmationCode, setConfirmationCode] = useState([])

  const codeInputRefs = [useRef(), useRef(), useRef(), useRef()]

  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    const confirmationCodeFromUrl = query.get('confirmationCode')
    console.log(confirmationCodeFromUrl)
    if (confirmationCodeFromUrl !== null) {
      const confirmationCodeArr = confirmationCodeFromUrl.split('')
      if (confirmationCodeArr.length === 4) {
        setConfirmationCode(confirmationCodeArr)
        callVerifyEmail(true)
      }
    }
  }, [])

  const changeConfirmationCode = (value, index) => {
    setConfirmationCode((prevCode) => {
      const newCode = [...prevCode]
      newCode[index] = value.charAt(value.length - 1)
      return newCode
    })

    if (value === '' && index !== 0) {
      codeInputRefs[index - 1].current.focus()
    } else if (value !== '' && index !== 3) {
      codeInputRefs[index + 1].current.focus()
    }
  }

  const switchToPreviousInput = (event, index) => {
    if (!confirmationCode[index] && index !== 0 && event.keyCode === 8) {
      event.preventDefault()
      codeInputRefs[index - 1].current.focus()
    }
  }

  const callVerifyEmail = (isChatbot = false) => {
    const code = isChatbot ? query.get('confirmationCode') : confirmationCode.join('')
    const email = query.get('email')
    const url = `/signup/registration${isChatbot ? '?isChatbot=true' : ''}`
    verifyEmail(email, code)
      .then(() => {
        history.push(url)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <Card className={classes.EmailConfirmationPage}>
        <Card.Body className={classes.cardBody}>
          <Card.Title className={classes.title}>Email Confirmation</Card.Title>
          <Card.Text className={classes.cardText}>
            <p>
              We sent an email to contact@email.com. Enter the code bellow that
              we provided through email
            </p>
          </Card.Text>
          <div className={classes.codeContainer}>
            <input
              type="text"
              maxLength={1}
              value={confirmationCode[0]}
              ref={codeInputRefs[0]}
              onChange={(event) => {
                changeConfirmationCode(event.target.value.toUpperCase(), 0)
              }}
              style={{ backgroundColor: confirmationCode[0] ? '#666666' : '' }}
            />
            <input
              type="text"
              maxLength={1}
              value={confirmationCode[1]}
              ref={codeInputRefs[1]}
              onChange={(event) => {
                changeConfirmationCode(event.target.value.toUpperCase(), 1)
              }}
              onKeyDown={(event) => {
                switchToPreviousInput(event, 1)
              }}
              style={{ backgroundColor: confirmationCode[1] ? '#666666' : '' }}
            />
            <input
              type="text"
              maxLength={1}
              value={confirmationCode[2]}
              ref={codeInputRefs[2]}
              onChange={(event) => {
                changeConfirmationCode(event.target.value.toUpperCase(), 2)
              }}
              onKeyDown={(event) => {
                switchToPreviousInput(event, 2)
              }}
              style={{ backgroundColor: confirmationCode[2] ? '#666666' : '' }}
            />
            <input
              type="text"
              maxLength={1}
              value={confirmationCode[3]}
              ref={codeInputRefs[3]}
              onChange={(event) => {
                changeConfirmationCode(event.target.value.toUpperCase(), 3)
              }}
              onKeyDown={(event) => {
                switchToPreviousInput(event, 3)
              }}
              style={{ backgroundColor: confirmationCode[3] ? '#666666' : '' }}
            />
          </div>
          <div className={classes.buttonContainer}>
            <StyledButton
              label="Log in"
              style={{ height: 52 }}
              onClick={() => {
                callVerifyEmail()
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
export default connect(
  (state) => ({
    isFetching: state.users.isFetching
  }),
  {
    verifyEmail: authActions.verifyEmail
  }
)(EmailConfirmationPage)
