import { GET_CUSTOMER_SUCCESS, FETCHING, GET_CUSTOMER_FAIL } from '../types'

const initialState = {
  customer: null,
  isFetching: false
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: payload,
        isFetching: false
      }
    case GET_CUSTOMER_FAIL:
      return {
        ...state,
        isFetching: false
      }
    case FETCHING:
      return {
        ...state,
        isFetching: payload
      }

    default:
      return state
  }
}
