import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import classnames from 'classnames'

import * as contractsActions from '../../../redux/actions/contract'

import classes from './styles.module.scss'
import lightningIcon from '../../../assets/images/lightningMin.svg'
import lightningIconBlue from '../../../assets/images/lightningMinBlue.svg'

function CompanyCard ({
  contract,
  addContractToPay,
  contractsToPay,
  removeContractToPay
}) {
  const [selected, setSelected] = useState(false)
  const selectContract = () => {
    if (!contractsToPay.contracts.includes(contract)) {
      setSelected(true)
      addContractToPay(contract)
    } else {
      setSelected(false)
      removeContractToPay(contract)
    }
  }

  useEffect(() => {
    if (contractsToPay.contracts.includes(contract)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [])

  return (
    <div
      className={
        selected
          ? classnames(classes.CompanyCard, classes.borderCard)
          : classes.CompanyCard
      }
      onClick={selectContract}
    >
      <div className={classes.wrapper}>
        <div className={classes.boxImg}>
          {selected
            ? (
            <img src={lightningIconBlue} alt="LightningBlue" />
              )
            : (
            <img src={lightningIcon} alt="Lightning" />
              )}
        </div>
        <div className={classes.companyInfo}>
          <div className={classes.contactBox}>
            <span className={classes.contact}>Contract: </span>
            <span className={classes.numberPhone}>{contract.CONTRATO}</span>
          </div>
          <div className={classes.inmubleBox}>
            <span className={classes.inmuble}>Inmuble: </span>
            <span className={classes.inmubleValue}>{contract.INMUEBLE}</span>
          </div>
          <div className={classes.titularBox}>
            <span className={classes.titular}>Titular: </span>
            <span className={classes.titularValue}>{contract.NOMBRE}</span>
          </div>
          <div className={classes.amountBoxMobile}>
            <span className={classes.titular}>Amount: </span>
            <span className={classes.titularValue}>
              ${parseFloat(contract.SALDO).toLocaleString('en-US')} USD
            </span>
            <span className={classes.titularValue}>
              ${parseFloat(contract.SALDODOP).toLocaleString('en-US')} DOP
            </span>
          </div>
        </div>
      </div>
      <div className={classes.amountBox}>
        <span className={classes.amount}>Amount:</span>
        <span className={classes.amountValue}>
          ${parseFloat(contract.SALDO).toLocaleString('en-US')} USD
        </span>
        <span className={classes.amountValue}>
          ${parseFloat(contract.SALDODOP).toLocaleString('en-US')} DOP
        </span>
      </div>
    </div>
  )
}

export default connect(
  (state) => ({
    contractsToPay: state.contracts.contractsToPay
  }),
  {
    addContractToPay: contractsActions.addContractToPay,
    removeContractToPay: contractsActions.removeContractToPay
  }
)(CompanyCard)
