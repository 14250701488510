import { combineReducers } from 'redux'

import { rootReducer as users } from './reducers/user'
import { rootReducer as auth } from './reducers/auth'
import { rootReducer as customers } from './reducers/customer'
import { rootReducer as contracts } from './reducers/contract'
import { rootReducer as invoices } from './reducers/invoice'
import { rootReducer as cards } from './reducers/card'
import { rootReducer as transactions } from './reducers/transaction'
import { rootReducer as chatbot } from './reducers/chatbot'

const reducers = combineReducers({
  auth,
  users,
  customers,
  contracts,
  invoices,
  cards,
  transactions,
  chatbot
})

export default reducers
