import React from 'react'

import { useHistory } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import classnames from 'classnames'
import * as Yup from 'yup'
import { Formik } from 'formik'

import EmptyHeaderWithLogo from '../../../components/EmptyHeaderWithLogo'
import FormField from '../../../components/Form/FormField'
import FormButton from '../../../components/Form/StyledButton'
import ErrorMessage from '../../../components/Form/ErrorMessage'

import classes from './styles.module.scss'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email')
})

export default function PasswordResetPage2 () {
  const history = useHistory()
  return (
    <div className={classes.PasswordResetPage}>
      <EmptyHeaderWithLogo biggerVariant incorrectPasswordVariant />
      <div className={classes.contentContainer}>
        <p className={classes.details}>
          Do you still have access to the email that is associated with your
          account ?
        </p>
        <Formik
          initialValues={{ email: 'contact@email.com' }}
          validationSchema={ValidationSchema}
          onSubmit={() => {
            history.push('/passwordReset/3')
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
            // isSubmitting,
          }) => (
            <Form>
              <div className={classes.formFieldContainer}>
                <FormField
                  label="Email"
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                />
                {touched.email && errors.email
                  ? (
                  <ErrorMessage message={errors.email} />
                    )
                  : null}
              </div>
              <div className={classes.buttonContainer}>
                <FormButton
                  label="I don’t have access"
                  secondary
                  onClick={handleSubmit}
                />
              </div>
              <div
                className={classnames(classes.buttonContainer, classes.lastOne)}
              >
                <FormButton
                  label="Send email conformation"
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
