import React from 'react'
import { useMediaQuery } from 'react-responsive'

import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'

import classes from './styles.module.scss'

export default function AppContainerWithMenu ({ children }) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  return (
    <div className={classes.AppContainerWithMenu}>
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <div className={classes.backgroundContainer} />
      {children}
    </div>
  )
}
