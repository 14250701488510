import React from 'react'
import Title from '../../components/Account/Title'
import checkCircle from '../../assets/images/checkCircle.svg'
import classes from './styles.module.scss'

const InformationSuccess = ({ text }) => {
  return (
    <>
      <Title text="Information" />
      <div className={classes.boxImg}>
        <img src={checkCircle} alt="checkCircle" />
      </div>
      <div className={classes.message}>
        <p>{text}</p>
      </div>
    </>
  )
}

export default InformationSuccess
