import React, { useState } from 'react'

import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'
import { useLocation } from 'react-router-dom'
import { IoIosInformationCircleOutline } from 'react-icons/io'

import FormErrorMessage from '../../../components/Messages/ErrorMessage'
import EmptyHeader from '../../../components/EmptyHeader'
import Header from '../../../components/Header'
import FormField from '../../../components/Form/FormField'
import FormButton from '../../../components/Form/StyledButton'
import * as usersActions from '../../../redux/actions/user'
import authActions from '../../../redux/actions/auth'
import forceTokenRefresh from '../../../helpers/forceTokenRefresh'
import Spinner from '../../../components/Spinner'

import classes from './styles.module.scss'
import clientCodeImage from '../../../assets/images/Client_ID.png'

export function ClientCodePage ({ patchUser, history, updateHasClientCode }) {
  const [clientCode, setClientCode] = useState('')
  const [serverError, setServerError] = useState(null)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false)

  const query = new URLSearchParams(useLocation().search)

  const isAddNewCodePage = !!query.get('addNew')

  return (
    <div className={classes.ClientCodePage}>
      {isAddNewCodePage ? <Header /> : <EmptyHeader />}
      <div className={classes.backgroundContainer} />
      <div className={classes.cardContainer}>
        <Card className={classes.clientCodeCard}>
          {isSpinnerVisible
            ? (
            <div
              style={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Spinner />
            </div>
              )
            : (
            <Card.Body className={classes.cardBody}>
              <Card.Title className={classes.cardTitle}>
                Client ID Number
              </Card.Title>
              <p className={classes.details}>
                Please enter your client id number to link your active contracts
                to your account.
              </p>
              <div className={classes.formFieldContainer}>
                <div
                  className={classes.tooltip}
                  style={{ display: isTooltipVisible ? 'block' : 'none' }}
                >
                  <img src={clientCodeImage} alt="Client code" width={350} />
                </div>
                <IoIosInformationCircleOutline
                  className={classes.infoIcon}
                  size={24}
                  onMouseOver={() => setIsTooltipVisible(true)}
                  onMouseOut={() => setIsTooltipVisible(false)}
                />
                <FormField
                  label="Code"
                  type="text"
                  value={clientCode}
                  onChange={(event) => setClientCode(event.target.value)}
                />
              </div>
              <div className={classes.formFieldContainer}>
                <FormButton
                  label="Next"
                  onClick={() => {
                    setServerError(null)
                    setIsSpinnerVisible(true)
                    patchUser({
                      clientCode,
                      addClientCode: !!query.get('addNew')
                    })
                      .then(async () => {
                        updateHasClientCode()
                        await forceTokenRefresh()
                        setIsSpinnerVisible(false)
                        history.push(
                          !query.get('addNew')
                            ? '/signup/creditCardRegistration?afterSignUp=true'
                            : '/dashboard/contracts'
                        )
                      })
                      .catch((error) => {
                        setIsSpinnerVisible(false)
                        console.log(error.data)
                        setServerError(error.data.details)
                      })
                  }}
                />
              </div>
            </Card.Body>
              )}
        </Card>
        <div>{serverError && <FormErrorMessage errors={serverError} />}</div>
      </div>
    </div>
  )
}

export default connect(null, {
  patchUser: usersActions.patchUser,
  updateHasClientCode: authActions.updateHasClientCode
})(ClientCodePage)
