import React, { useEffect } from 'react'

import axios from 'axios'
import { useLocation, useHistory } from 'react-router-dom'

import { API_URL } from '../../constants/main'

export default function ResetPasswordPage () {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token')
  useEffect(() => {
    if (token) {
      axios
        .post(`${API_URL}/reset-password`, { token })
        .then(() => {
          history.push('/login')
        })
        .catch((error) => {
          console.log(error)
          history.push('/login')
        })
    } else {
      history.push('/login')
    }
  }, [])
  return <div>{token}</div>
}
