export default function GetCardType (number) {
  const numberWithoutXs = number.replace(/X/g, '1')
  // visa
  let re = '^4'

  if (numberWithoutXs.match(re) != null) return 'Visa'

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      numberWithoutXs
    )
  ) { return 'Mastercard' }

  // AMEX
  re = '^3[47]'
  if (numberWithoutXs.match(re) != null) return 'AMEX'

  // Discover
  re = '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
  if (numberWithoutXs.match(re) != null) return 'Discover'

  // Diners
  re = '^36'
  if (numberWithoutXs.match(re) != null) return 'Diners'

  // Diners - Carte Blanche
  re = '^30[0-5]'
  if (numberWithoutXs.match(re) != null) return 'Diners - Carte Blanche'

  // JCB
  re = '^35(2[89]|[3-8][0-9])'
  if (numberWithoutXs.match(re) != null) return 'JCB'

  // Visa Electron
  re = '^(4026|417500|4508|4844|491(3|7))'
  if (numberWithoutXs.match(re) != null) return 'Visa Electron'

  return ''
}
