import React from 'react'

import classes from './styles.module.scss'

export default function index () {
  return (
    <div className={classes.ldsRing}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
