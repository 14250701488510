import axios from 'axios'

const register = (values) => {
  return axios.post('/users', {
    username: values.username,
    email: values.email,
    password: values.password1
  })
}

const login = (email, password) => {
  return axios.post('/login', {
    email,
    password
  })
}

const loginSession = ({ sessionID, pathname }) => {
  return axios.post('/login-session', {
    sessionID,
    pathname
  })
}

const resetPasswordUser = (email) => {
  return axios.post('/forgot-password', {
    email
  })
}
const createNewPassword = ({ newPassword, token }) => {
  return axios.post('/reset-password', {
    password: newPassword,
    token
  })
}

const logout = () => {
  return axios.post('/auth/logout/')
}

const verifyEmail = (email, confirmationCode) => {
  return axios.post('/users/verify-email/', { email, confirmationCode })
}

export default {
  register,
  login,
  loginSession,
  resetPasswordUser,
  createNewPassword,
  logout,
  verifyEmail
}
