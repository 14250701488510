import React, { useState } from 'react'

import Form from 'react-bootstrap/Form'

import classes from './styles.module.scss'
import eye from '../../../assets/images/eye.svg'

export default function FormField ({
  label,
  placeholder,
  value,
  onChange,
  readOnly,
  onBlur
}) {
  const [isShowPassword, setIsShowPassword] = useState(false)
  return (
    <Form.Group controlId={`formBasic${label}`} className={classes.FormField}>
      <Form.Label className={classes.formLabel}>{label}</Form.Label>
      <div className={classes.formFieldContainer}>
        <Form.Control
          className={classes.formControl}
          type={isShowPassword ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          onBlur={onBlur}
        />
        <div
          className={classes.eyeContainer}
          onClick={() => setIsShowPassword(!isShowPassword)}
        >
          <img src={eye} alt="Eye" />
        </div>
      </div>
    </Form.Group>
  )
}
