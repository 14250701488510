import React, { useState } from 'react'

import Form from 'react-bootstrap/Form'
import MaskInput from 'react-maskinput'
import classnames from 'classnames'

import classes from './styles.module.scss'

export default function ExpCardDateFieldWithInnerLabel ({
  label,
  type,
  placeholder,
  value,
  onChange,
  readOnly,
  onBlur
}) {
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder)
  const [isFocused, setIsFocused] = useState(false)

  const isFirefox = navigator.userAgent.indexOf('Firefox') > -1

  return (
    <Form.Group
      controlId={`formBasic${label}`}
      className={classes.ExpCardDateFieldWithInnerLabel}
    >
      {(value !== '' || isFocused) && (
        <Form.Label className={classes.formLabel}>{label}</Form.Label>
      )}
      {isFocused && !value && (
        <div className={classes.placeholder}>mm/yyyy</div>
      )}
      <MaskInput
        // showMask
        // maskChar="_"
        mask="00 0000"
        className={
          value !== ''
            ? classnames(classes.formControl, classes.notEmpty)
            : classes.formControl
        }
        style={{ paddingTop: isFirefox ? 18 : '' }}
        type={type}
        placeholder={currentPlaceholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={() => {
          setCurrentPlaceholder(placeholder)
          setIsFocused(false)
          if (onBlur) {
            onBlur()
          }
        }}
        onFocus={() => {
          setCurrentPlaceholder('')
          setIsFocused(true)
        }}
      />
    </Form.Group>
  )
}
