import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

import * as contractsActions from '../../../redux/actions/contract'
import * as usersActions from '../../../redux/actions/user'
import * as customersActions from '../../../redux/actions/customer'
import * as invoicesActions from '../../../redux/actions/invoice'

import Spinner from '../../../components/Spinner'
import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'
import Title from '../../../components/Account/Title'
import CompanyCard from '../../../components/Dashboard/CompanyCard'
import FormButton from '../../../components/Form/StyledButton'
import paginateRight from '../../../assets/images/paginateRight.svg'
import paginateLeft from '../../../assets/images/paginateLeft.svg'
import { DEFAULT_PAGE_SIZE } from '../../../constants/main'

const COMPANY_TYPES = {
  electricity: 'CCAR',
  telephone: 'CTEL',
  water: 'AGUA'
}

function ContractsList ({
  contracts,
  history,
  user,
  contractsToPay,
  getContracts,
  getUser,
  resetContractsToPay,
  setContractToPayCompanyName,
  getCustomer,
  customer,
  resetInvoicesToPay,
  areContractsFetching
}) {
  const [pageNumber, setPageNumber] = useState(1)
  const [filteredContracts, setFilteredContracts] = useState([])
  const [
    filteredAndPaginatedContracts,
    setFilteredAndPaginatedContracts
  ] = useState([])

  const pageCount = Math.ceil(filteredContracts.length / DEFAULT_PAGE_SIZE)

  const query = new URLSearchParams(useLocation().search)

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1)
  }

  useEffect(() => {
    getUser()
    resetContractsToPay()
    resetInvoicesToPay()
    setContractToPayCompanyName(
      query.get('companyName') ? query.get('companyName') : 'Some Company'
    )
  }, [])

  useEffect(() => {
    if (user && !customer) {
      getCustomer(user.pk)
    }
  }, [user])

  useEffect(() => {
    if (customer) {
      console.log('REQUESTING CONTRACTS')
      getContracts(customer.code)
    }
  }, [customer])

  useEffect(() => {
    if (contracts.length) {
      setFilteredContracts(
        contracts.filter(
          (contract) =>
            contract.SOCIEDAD === COMPANY_TYPES[query.get('companyType')] &&
            contract.SALDO !== '0.0'
        )
      )
    }
  }, [contracts])

  useEffect(() => {
    setFilteredAndPaginatedContracts(
      filteredContracts.slice(
        (pageNumber - 1) * DEFAULT_PAGE_SIZE,
        (pageNumber - 1) * DEFAULT_PAGE_SIZE + DEFAULT_PAGE_SIZE
      )
    )
  }, [pageNumber, filteredContracts])

  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.ContractsList}>
          <div className={classes.col}>
            <Card className={classes.boxCompanyList}>
              <Card.Body className={classes.boxCompanyListBody}>
                <div className={classes.title}>
                  <Title
                    text={
                      query.get('companyName')
                        ? query.get('companyName')
                        : 'Some Company'
                    }
                  />
                </div>
                {areContractsFetching
                  ? (
                  <div className={classes.spinnerContainer}>
                    <Spinner />
                  </div>
                    )
                  : (
                  <div className={classes.companyList}>
                    {filteredAndPaginatedContracts.length
                      ? (
                          filteredAndPaginatedContracts.map((contract) => {
                            return contract.SALDO !== '0.0'
                              ? (
                          <CompanyCard
                            key={contract.CONTRATO}
                            contract={contract}
                          />
                                )
                              : null
                          })
                        )
                      : (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        No contract found
                      </div>
                        )}
                  </div>
                    )}
                <div className={classes.pagination}>
                  <ReactPaginate
                    previousClassName={classes.previous}
                    nextClassName={classes.next}
                    activeClassName={classes.paginationActive}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={classes.paginationBttns}
                    previousLabel={<img src={paginateLeft} alt="arrowLeft" />}
                    nextLabel={<img src={paginateRight} alt="arrowRight" />}
                    previousLinkClassName="previousBttn"
                    nextLinkClassName="nextBttn"
                    disabledClassName="paginationDisabled"
                  />
                </div>
              </Card.Body>
            </Card>
            {contractsToPay.contracts.length
              ? (
              <div className={classes.btnPayNow}>
                <FormButton
                  label="Pay now"
                  onClick={() => history.push('/dashboard/contracts/group')}
                />
              </div>
                )
              : null}
          </div>
        </div>
      </AppContainerWithMenu>
    </>
  )
}

export default connect(
  (state) => ({
    areContractsFetching: state.contracts.isFetching,
    contracts: state.contracts.contracts,
    contractsToPay: state.contracts.contractsToPay,
    user: state.users.user,
    isUserFetching: state.users.isFetching,
    customer: state.customers.customer
  }),
  {
    getContracts: contractsActions.getContracts,
    resetContractsToPay: contractsActions.resetContractsToPay,
    setContractToPayCompanyName: contractsActions.setContractsToPayCompanyName,
    getUser: usersActions.getUser,
    getCustomer: customersActions.getCustomer,
    resetInvoicesToPay: invoicesActions.resetInvoicesToPay
  }
)(ContractsList)
