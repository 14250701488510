import React, { useRef, useEffect, useState } from 'react'

import classnames from 'classnames'

import classes from './styles.module.scss'

export default function StyledButton ({
  label,
  onClick,
  secondary,
  narrow,
  subtle,
  text,
  disabled,
  white,
  grayBorder,
  isLoading,
  style
}) {
  const [clickWidth, setClickWidth] = useState(null)
  const [clickXPosition, setClickXPosition] = useState(null)
  const [clickYPosition, setClickYPosition] = useState(null)

  const buttonRef = useRef()

  useEffect(() => {
    setClickWidth(buttonRef.current.clientHeight)
  }, [buttonRef?.current?.clientHeight])
  const spinner = (
    <div
      className={classnames(
        classes.spinner,
        secondary && classes.secondary,
        subtle && classes.subtle,
        text && classes.text
      )}
    >
      <span className={classes.loadingMessage}>Loading...</span>
      <div />
      <div />
      <div />
      <div />
    </div>
  )

  return (
    <button
      className={classnames(
        classes.StyledButton,
        secondary && classes.secondary,
        subtle && classes.subtle,
        text && classes.text,
        narrow && classes.narrow,
        white && classes.white,
        grayBorder && classes.grayBorder,
        isLoading && classes.isLoading
      )}
      onClick={(event) => {
        const coords = buttonRef.current.getBoundingClientRect()
        console.log(window.scrollY)
        setClickXPosition(event.pageX - coords.left)
        setClickYPosition(event.pageY - coords.top)
        setTimeout(() => {
          setClickXPosition(null)
          setClickYPosition(null)
          onClick()
        }, 100)
      }}
      disabled={disabled || isLoading}
      type="button"
      style={style}
      ref={buttonRef}
    >
      <div
        className={classes.click}
        style={{
          display: clickXPosition ? 'block' : 'none',
          width: buttonRef.current ? clickWidth * 0.86 : '100%',
          top:
            clickYPosition -
            (buttonRef.current ? clickWidth * 0.86 : '100%') / 2 -
            window.scrollY,
          left:
            clickXPosition -
            (buttonRef.current ? clickWidth * 0.86 : '100%') / 2
        }}
      />
      {isLoading ? spinner : label}
    </button>
  )
}
