import React, { useState, useEffect } from 'react'

import { useHistory, NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import classnames from 'classnames'
/* import { actions as userActions } from '../../resources/users'; */
import authActions from '../../redux/actions/auth'

import classes from './styles.module.scss'
import logo from '../../assets/images/logo.svg'

function Header ({ blueClip, logoutUser }) {
  const wrapper = React.createRef()
  const [isAccountDropDownVisible, setIsAccountDropDownVisible] = useState(
    false
  )
  const [isDashboardDropDownVisible, setIsDashboardDropDownVisible] = useState(
    false
  )

  const history = useHistory()

  const onClickOutside = () => {
    if (isAccountDropDownVisible) {
      setIsAccountDropDownVisible((prevState) => !prevState)
    }
    if (isDashboardDropDownVisible) {
      setIsDashboardDropDownVisible((prevState) => !prevState)
    }
  }

  const handleDocumentClick = (e) => {
    if (wrapper.current && !wrapper.current.contains(e.target)) {
      onClickOutside()
    }
  }

  const toggleAccountDropDown = () => {
    setIsAccountDropDownVisible((prevState) => !prevState)
  }

  /* const toggleDashboardDropDown = () => {
    setIsDashboardDropDownVisible((prevState) => !prevState);
  }; */

  useEffect(() => {
    if (isAccountDropDownVisible || isDashboardDropDownVisible) {
      document.addEventListener('click', handleDocumentClick)
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  })

  return (
    <Navbar className={classes.Header}>
      <i className={classes.backButton} onClick={() => history.goBack()}>
        Back button
      </i>
      <Container className={classes.headerContainer}>
        <Navbar.Brand to="/" className={classes.logo} as={Link}>
          <img alt="logo" src={logo} />
        </Navbar.Brand>
        <Nav>
          <div
            className={classnames(
              classes.dropDownToggle,
              isAccountDropDownVisible && classes.active,
              isDashboardDropDownVisible && classes.disabled
            )}
            ref={wrapper}
            onClick={toggleAccountDropDown}
          >
            <span>Account</span>
            <div
              className={classnames(
                classes.dropDownContent,
                classes.accountDropDown,
                isAccountDropDownVisible && classes.visible
              )}
            >
              <span>Account</span>
              <NavLink onClick={toggleAccountDropDown} to="/account/profile">
                Profile
              </NavLink>
              <NavLink onClick={toggleAccountDropDown} to="/account/wallet">
                Wallet
              </NavLink>
            </div>
          </div>
          <NavLink
            to="/dashboard/contracts"
            className={
              isDashboardDropDownVisible || isAccountDropDownVisible
                ? classnames(classes.navLink, classes.disabled)
                : classes.navLink
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/history"
            className={
              isDashboardDropDownVisible || isAccountDropDownVisible
                ? classnames(classes.navLink, classes.disabled)
                : classes.navLink
            }
          >
            Transaction History
          </NavLink>
          <NavLink
            to="#"
            onClick={logoutUser}
            className={
              isDashboardDropDownVisible || isAccountDropDownVisible
                ? classnames(classes.navLink, classes.disabled)
                : classes.navLink
            }
            style={{ marginRight: 0 }}
          >
            Log Out
          </NavLink>
        </Nav>
      </Container>
      <div
        className={
          blueClip ? classnames(classes.clip, classes.blue) : classes.clip
        }
      />
    </Navbar>
  )
}

export default connect(null, {
  logoutUser: authActions.logoutUser
})(Header)
