/* eslint-disable no-nested-ternary */
import React from 'react'

import moment from 'moment'

import classes from './styles.module.scss'
import waterIconMin from '../../../assets/images/waterMin.svg'
import lightningIconMin from '../../../assets/images/lightningMin.svg'
import phoneIcon from '../../../assets/images/phone.svg'

export default function InvoiceDetailCard ({ invoice }) {
  return (
    <div className={classes.InvoiceDetailCard}>
      <div className={classes.LeftWrap}>
        <div className={classes.boxImg}>
          <img
            src={
              invoice.SOCIEDAD === 'AGUA' || invoice.sociedad === 'AGUA'
                ? waterIconMin
                : invoice.SOCIEDAD === 'CCAR' || invoice.sociedad === 'CCAR'
                  ? lightningIconMin
                  : phoneIcon
            }
            style={{ height: invoice.SOCIEDAD === 'CTEL' ? 31 : 'auto' }}
            alt="Water"
          />
        </div>
        <div className={classes.companyInfo}>
          <div className={classes.contactBox}>
            <div className={classes.label}>Factura: </div>
            <div className={classes.value}>
              {invoice.FACTURA || invoice.factura}
            </div>
          </div>
          <div className={classes.contactBox}>
            <div className={classes.label}>Fecha: </div>
            <div className={classes.value}>
              {moment(invoice.FECHA_FAC || invoice.fechaFac).format(
                'MMM/DD/YY'
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.amountBox}>
        <span className={classes.amount}>Amount:</span>
        <span className={classes.amountValue}>
          ${invoice.SALDO || parseFloat(invoice.amount).toLocaleString('en-US')}
        </span>
        <span className={classes.amountValue}>
          $
          {invoice.SALDODOP ||
            parseFloat(invoice.amountDOP).toLocaleString('en-US')}
        </span>
      </div>
    </div>
  )
}
