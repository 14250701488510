import {
  GET_TRANSACTIONS_SUCCESS,
  GET_ORDER_SUCCESS,
  FETCHING
} from '../types'

const initialState = {
  transactions: null,
  order: null,
  isFetching: false
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload
      }
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: payload
      }
    case FETCHING:
      return {
        ...state,
        isFetching: payload
      }
    default:
      return state
  }
}
