import axios from 'axios'

const getCards = () => {
  return axios.get('/cards')
}

const saveCard = (values) => {
  return axios.post('/cards', {
    ...values
  })
}

const deleteCard = (cardId) => {
  return axios.delete(`/cards/${cardId}`)
}

export default {
  saveCard,
  getCards,
  deleteCard
}
