import React, { useEffect, useContext, useState } from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { PaymentContext } from '../../../context/index'

import InvoiceDetailCard from '../../../components/Plus/InvoiceDetailCard'
import Button from '../../../components/Form/StyledButton'
import logo from '../../../assets/images/bigLogoNew.png'
import mark from '../../../assets/images/mark.svg'
import calendar from '../../../assets/images/calendar.svg'
import payment from '../../../assets/images/payment.svg'
import billNumber from '../../../assets/images/billNumber.svg'
import bank from '../../../assets/images/bankIcon.svg'

import classes from './styles.module.scss'

function PrintPage ({ contractsToPay, invoicesToPay }) {
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false)

  const { cardNumber, processedInvoices } = useContext(PaymentContext)

  const history = useHistory()

  useEffect(() => {
    console.log(processedInvoices)
    setTimeout(() => {
      window.print()
    }, 2000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsBackButtonVisible(true)
    }, 2500)
  }, [])

  return (
    <>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
      </header>
      <div className={classes.contentWrapp}>
        <h1 className={classes.title}> Invoice: </h1>
        {contractsToPay.contracts.map((contract) => {
          return invoicesToPay.find(
            (invoice) => invoice.CONTRATO === contract.CONTRATO
          )
            ? (
            <Card className={classes.invoiceCard} key={contract.CONTRATO}>
              <Card.Body className={classes.invoiceCardBody}>
                <div className={classes.invoiceWrapp}>
                  <div className={classes.orderInfo}>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={mark} alt="mark" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Inmuble: </span>
                        </div>
                        <div className={classes.value}>
                          <span>{contract.INMUEBLE}</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={calendar} alt="calendar" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Fecha: </span>
                        </div>
                        <div className={classes.value}>
                          <span>{moment().format('MMM/DD/YY')}</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={payment} alt="Payment" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Payment: </span>
                        </div>
                        <div className={classes.value}>
                          <span>XXXX-XXXX-XXXX-XX{cardNumber.slice(-2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={billNumber} alt="billNumber" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Bills:</span>
                        </div>
                        <div className={classes.value}>
                          <span>
                            Number of bills:{' '}
                            {
                              invoicesToPay.filter(
                                (invoice) =>
                                  invoice.CONTRATO === contract.CONTRATO
                              ).length
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.orderInfoItem}>
                      <div className={classes.boxImg}>
                        <img src={bank} alt="bank" />
                      </div>
                      <div className={classes.boxInfoText}>
                        <div className={classes.label}>
                          <span>Authorization number: </span>
                        </div>
                        <div className={classes.value}>
                          <span>
                            {processedInvoices[0].value.authorizationNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.contentBox}>
                    <div className={classes.label}>
                      <span>{contract.SOCIEDAD}</span>
                    </div>
                    <div className={classes.boxInfoText}>
                      <span className={classes.label}>Contrato: </span>
                      <span className={classes.value}>{contract.CONTRATO}</span>
                    </div>
                  </div>
                  <div className={classes.invoiceItems}>
                    {invoicesToPay.map((invoice) => {
                      return invoice.CONTRATO === contract.CONTRATO
                        ? (
                        <InvoiceDetailCard
                          invoice={invoice}
                          key={invoice.FACTURA}
                        />
                          )
                        : null
                    })}
                  </div>
                </div>
              </Card.Body>
            </Card>
              )
            : null
        })}
        <div className={classes.total}>
          <span className={classes.label}>TOTAL: </span>
          <span className={classes.value}>
            {invoicesToPay
              .reduce((accumulator, invoice) => accumulator + +invoice.SALDO, 0)
              .toFixed(2)}
          </span>
        </div>
        {isBackButtonVisible && (
          <Button
            label="Back"
            onClick={() => history.push('/')}
            style={{ marginBottom: 50 }}
          />
        )}
      </div>
    </>
  )
}

export default connect((state) => ({
  invoicesToPay: state.invoices.invoicesToPay,
  contractsToPay: state.contracts.contractsToPay,
  customer: state.customers.customer,
  user: state.users.user
}))(PrintPage)
