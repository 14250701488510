import React from 'react'

import classnames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import EmptyHeaderWithLogo from '../../../components/EmptyHeaderWithLogo'
import FormField from '../../../components/Form/FormField'
import FormButton from '../../../components/Form/StyledButton'

import classes from './styles.module.scss'

export default function PasswordResetPage3 () {
  const isMobile = useMediaQuery({ query: '(max-width: 570px)' })
  return (
    <div className={classes.PasswordResetPage}>
      <EmptyHeaderWithLogo biggerVariant incorrectPasswordVariant />
      <div className={classes.contentContainer}>
        <p className={classes.details}>
          Do you still have access to the phone number associated with your
          account ?
        </p>
        <div className={classes.formFieldContainer}>
          <FormField
            label="Phone"
            type="text"
            value="+ 2 415 5552671"
            readOnly
          />
        </div>
        <div className={classes.buttonContainer}>
          <FormButton label="I don’t have access" secondary />
        </div>
        <div className={classnames(classes.buttonContainer, classes.lastOne)}>
          <FormButton
            label={isMobile ? 'Send Sms Code' : 'Send email conformation'}
          />
        </div>
      </div>
    </div>
  )
}
