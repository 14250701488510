import {
  FETCHING,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_FAIL,
  ADD_CONTRACT_TO_PAY,
  REMOVE_CONTRACT_TO_PAY,
  RESET_CONTRACTS_TO_PAY,
  SET_CONTRACTS_TO_PAY_COMPANY_NAME
} from '../types'

const initialState = {
  contracts: [],
  contractsToPay: { companyTitle: '', contracts: [] },
  isFetching: false
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: payload,
        isFetching: false
      }
    case GET_CONTRACTS_FAIL:
      return {
        ...state,
        isFetching: false
      }
    case FETCHING:
      return {
        ...state,
        isFetching: true
      }
    case ADD_CONTRACT_TO_PAY:
      return {
        ...state,
        contractsToPay: {
          companyTitle: state.contractsToPay.companyTitle,
          contracts: [...state.contractsToPay.contracts, payload]
        }
      }
    case REMOVE_CONTRACT_TO_PAY:
      return {
        ...state,
        contractsToPay: {
          companyTitle: state.contractsToPay.companyTitle,
          contracts: state.contractsToPay.contracts.filter(
            (contract) => contract !== payload
          )
        }
      }
    case RESET_CONTRACTS_TO_PAY:
      return {
        ...state,
        contractsToPay: { companyTitle: '', contracts: [] }
      }
    case SET_CONTRACTS_TO_PAY_COMPANY_NAME:
      return {
        ...state,
        contractsToPay: {
          companyTitle: payload,
          contracts: [...state.contractsToPay.contracts]
        }
      }
    default:
      return state
  }
}
