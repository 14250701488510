import React, { useState, useEffect } from 'react'

import { useHistory, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'

/* import { actions as userActions } from '../../resources/users'; */
import authActions from '../../redux/actions/auth'
import smallLogo from '../../assets/images/logoSmall.svg'
import classes from './styles.module.scss'

function MobileHeader ({ logoutUser, isAuth }) {
  const history = useHistory()
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false)
  const [isAccountDropDownOpened, setIsAccountDropDownOpened] = useState(false)
  const [isDashboardDropDownOpened, setIsDashboardDropDownOpened] = useState(
    false
  )

  const showSideMenu = () => {
    setIsSideMenuVisible(true)
  }

  const hideSideMenu = () => {
    setIsSideMenuVisible(false)
    setIsAccountDropDownOpened(false)
    setIsDashboardDropDownOpened(false)
  }

  const toggleAccountDropDown = () => {
    setIsAccountDropDownOpened((prevState) => !prevState)
  }

  /* const toggleDashboardDropDown = () => {
    setIsDashboardDropDownOpened((prevState) => !prevState);
  }; */

  useEffect(() => {
    if (isSideMenuVisible) {
      document.body.style.overflow = 'hidden'
      document.documentElement.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
      document.documentElement.overflow = 'visible'
    }
  }, [isSideMenuVisible])

  return (
    <header className={classes.MobileHeader}>
      {isAuth && (
        <i className={classes.backButton} onClick={() => history.goBack()}>
          Back
        </i>
      )}
      {isAuth && (
        <i className={classes.burgerButton} onClick={showSideMenu}>
          Burger button
        </i>
      )}
      {isSideMenuVisible && (
        <div className={classes.backDrop} onClick={hideSideMenu} />
      )}
      <div
        className={classes.sideMenu}
        style={{ right: isSideMenuVisible ? 0 : -278 }}
      >
        <header className={classes.sideMenuHeader}>
          <img src={smallLogo} alt="Logo" className={classes.logo} />
          <i className={classes.closeButton} onClick={hideSideMenu}>
            Close
          </i>
        </header>
        <div className={classes.menuButtonsContainer}>
          <div
            className={classnames(
              classes.menuButton,
              classes.dropDownToggle,
              isAccountDropDownOpened && classes.active,
              isDashboardDropDownOpened && classes.disabled
            )}
            onClick={toggleAccountDropDown}
          >
            <i className={classes.account}>Account</i>
            <span>Account</span>
          </div>
          {isAccountDropDownOpened && (
            <div className={classes.dropDown}>
              <NavLink onClick={hideSideMenu} to="/account/profile">
                Profile
              </NavLink>
              <NavLink onClick={hideSideMenu} to="/account/wallet">
                Wallet
              </NavLink>
            </div>
          )}
          {/* <div
            className={classnames(
              classes.menuButton,
              classes.dropDownToggle,
              isDashboardDropDownOpened && classes.active,
              isAccountDropDownOpened && classes.disabled
            )}
            onClick={toggleDashboardDropDown}
          >
            <i className={classes.dashboard}>Dashboard</i>
            <span>Dashboard</span>
          </div>
          {isDashboardDropDownOpened && (
            <div className={classes.dropDown}>
              <NavLink onClick={hideSideMenu} to="/dashboard/contracts">
                Contracts
              </NavLink>
            </div>
          )} */}
          <NavLink
            className={classnames(
              classes.menuButton,
              (isAccountDropDownOpened || isDashboardDropDownOpened) &&
                classes.disabled
            )}
            to="/dashboard/contracts"
            onClick={hideSideMenu}
          >
            <i className={classes.dashboard}>Dashboard</i>
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            className={classnames(
              classes.menuButton,
              (isAccountDropDownOpened || isDashboardDropDownOpened) &&
                classes.disabled
            )}
            to="/history"
            onClick={hideSideMenu}
          >
            <i className={classes.history}>History</i>
            <span>Transaction History</span>
          </NavLink>
          <div className={classes.logoutContainer}>
            <NavLink
              to="#"
              onClick={logoutUser}
              className={classnames(
                classes.menuButton,
                (isAccountDropDownOpened || isDashboardDropDownOpened) &&
                  classes.disabled
              )}
            >
              <i className={classes.logout}>Log Out</i>
              <span>Log Out</span>
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  )
}

export default connect(
  (state) => ({
    isAuth: state.auth.isLoggedIn
  }),
  {
    logoutUser: authActions.logoutUser
  }
)(MobileHeader)
