import React, { useState, useEffect, useContext } from 'react'

import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import moment from 'moment'
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import { Card } from 'react-bootstrap'
import cryptoRandomString from 'crypto-random-string'

import { PaymentContext } from '../../../context'
import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'

import FormField from '../../../components/Form/FormFieldWithInnerLabel'
import Spinner from '../../../components/Spinner'
import CardNumberField from '../../../components/Form/CardNumberFieldWithInnerLabel'
import ExpCardDateField from '../../../components/Form/ExpCardDateFieldWithInnerLabel'
import Title from '../../../components/Account/Title'
import PaymentDetailsCard from '../../../components/CheckOut/PaymentDetailsCard'
import FormButton from '../../../components/Form/StyledButton'
import masterCardLogo from '../../../assets/images/masterCardMin.svg'
import visaLogo from '../../../assets/images/visaMini.svg'

const digitsAndSpacesOnly = (value) => /[0-9 ]+/.test(value)
const digitsOnly = (value) => /^\d+$/.test(value)

const ValidationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required('Please enter card number')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(19),
  cardHolderName: Yup.string().required('Please enter your name'),
  expDate: Yup.string()
    .required('Please enter card expiration date')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(7),
  cvv: Yup.string()
    .required('Please enter card\'s cvv')
    .test('Digits only', 'The field should have digits only', digitsOnly)
    .length(3)
})

function PaymentDetails ({ history, invoicesToPay, isChatbot }) {
  const [cardType, setCardType] = useState('mastercard')
  const [contracts, setContracts] = useState([])
  const [isPaymentBeingProccesed, setIsPaymentBeingProcessed] = useState(false)

  const {
    orderNumber,
    setOrderNumber,
    cardNumber,
    cardHolderName,
    expDate,
    cvv,
    cardId,
    setProcessedInvoices,
    shouldCardBeSaved,
    address,
    city,
    postCode,
    country,
    state,
    shouldSaveAddress
  } = useContext(PaymentContext)

  if (!invoicesToPay.length) {
    history.replace('/')
  }

  useEffect(() => {
    if (cardNumber.length >= 2) {
      const typeCheck = parseInt(cardNumber.substring(0, 2), 10)
      if (typeCheck >= 40 && typeCheck <= 49) {
        setCardType('visa')
      } else if (typeCheck >= 51 && typeCheck <= 55) {
        setCardType('mastercard')
      }
    }
  }, [cardNumber])

  useEffect(() => {
    if (invoicesToPay.length) {
      const contractsSet = new Set()
      invoicesToPay.forEach((invoice) => {
        contractsSet.add(invoice.CONTRATO)
        setContracts(Array.from(contractsSet))
      })
    }
  }, [invoicesToPay])

  useEffect(() => {
    setOrderNumber(cryptoRandomString({ length: 10 }).toUpperCase())
  }, [])

  const makePayment = () => {
    setIsPaymentBeingProcessed(true)
    axios
      .post('/transactions', {
        cardId,
        cardNumber,
        cardHolderName,
        expDate,
        cvv,
        shouldCardBeSaved,
        address,
        city,
        postCode,
        country,
        state,
        orderCode: orderNumber,
        invoices: invoicesToPay,
        shouldSaveAddress,
        isChatbot
      })
      .then((response) => {
        // console.log(response.data);
        setIsPaymentBeingProcessed(false)
        setProcessedInvoices(response.data.data)
        history.push('/checkout/paymentConfirmation')
      })
      .catch((error) => {
        // const errorMessage = JSON.parse(error.response.data.detail[0]);
        setIsPaymentBeingProcessed(false)
        history.push(
          `/plus/paymentUnsuccessful?message=${error.response.data.detail[0]}`
        )
      })
  }

  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.PaymentDetails}>
          <div className={classes.col}>
            <Title text="Payment Details" />
            <div className={classes.detailsWrapp}>
              <Card className={classes.infoCard}>
                <Card.Body className={classes.infoCardBody}>
                  <div className={classes.infoTopWrapp}>
                    <div className={classes.fecha}>
                      <span className={classes.label}>Fecha: </span>
                      <span className={classes.value}>
                        {moment().format('MMM/DD/YY')}
                      </span>
                    </div>
                    <div className={classes.order}>
                      <span className={classes.label}>Order: </span>
                      <span className={classes.value}>{orderNumber}</span>
                    </div>
                  </div>
                  <Formik
                    validateOnMount
                    initialValues={{
                      orderNumber,
                      cardNumber,
                      cardHolderName,
                      expDate,
                      cvv
                    }}
                    validationSchema={cardId ? null : ValidationSchema}
                    onSubmit={() => {
                      makePayment()
                    }}
                  >
                    {({
                      values,
                      // errors,
                      // touched,
                      handleChange,
                      // handleBlur,
                      handleSubmit,
                      isValid
                      // dirty,
                      // isSubmitting,
                    }) => (
                      <Form className={classes.form}>
                        <div className={classes.formRowContainer}>
                          <FormField
                            readOnly={cardId}
                            label="Cardholder Name"
                            placeholder="Cardholder Name"
                            value={values.cardHolderName}
                            onChange={handleChange('cardHolderName')}
                          />
                        </div>
                        <div className={classes.formRowContainer}>
                          <div className={classes.formFieldCardNumber}>
                            <CardNumberField
                              readOnly={cardId}
                              label="Card Number"
                              placeholder="Card Number"
                              value={values.cardNumber}
                              onChange={handleChange('cardNumber')}
                            />
                          </div>
                          <div className={classes.cardType}>
                            {cardType === 'mastercard'
                              ? (
                              <img src={masterCardLogo} alt="MasterCard" />
                                )
                              : (
                              <img
                                style={{ position: 'relative', left: -1 }}
                                src={visaLogo}
                                alt="Visa"
                                width={38}
                                height={30}
                              />
                                )}
                          </div>
                        </div>
                        <div className={classes.formRowContainer}>
                          <div className={classes.formFieldContainer}>
                            <ExpCardDateField
                              readOnly={cardId}
                              label="Ex. Date"
                              placeholder="Ex. Date"
                              value={values.expDate}
                              onChange={handleChange('expDate')}
                            />
                          </div>
                          {!cardId && (
                            <div className={classes.formFieldContainer}>
                              <FormField
                                readOnly={cardId}
                                label="CVV"
                                placeholder="CVV"
                                value={values.cvv}
                                onChange={handleChange('cvv')}
                              />
                            </div>
                          )}
                        </div>
                        <div className={classes.btnContainer}>
                          <div className={classes.innerContainer}>
                            {isPaymentBeingProccesed
                              ? (
                              <div className={classes.spinnerContaner}>
                                <Spinner />
                              </div>
                                )
                              : (
                              <>
                                <FormButton
                                  onClick={() =>
                                    history.push('/dashboard/contracts')
                                  }
                                  secondary
                                  label="Cancel"
                                />
                                <FormButton
                                  label="Pay Now"
                                  disabled={!isValid}
                                  onClick={handleSubmit}
                                />
                              </>
                                )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
              <Card className={classes.infoHistory}>
                <Card.Body className={classes.infoHistoryBody}>
                  {contracts.map((contract) => {
                    return (
                      <div className={classes.infoTop} key={contract}>
                        <div className={classes.companyInfo}>
                          <div className={classes.topItem}>
                            <span className={classes.label}>
                              {invoicesToPay.length
                                ? invoicesToPay.find(
                                  (invoice) => invoice.CONTRATO === contract
                                ).SOCIEDAD
                                : null}
                            </span>
                          </div>
                          <div className={classes.contactBox}>
                            <span className={classes.label}>Contrato: </span>
                            <span className={classes.value}>{contract}</span>
                          </div>
                          <div className={classes.contactBox}>
                            <span className={classes.label}>Inmuble: </span>
                            <span className={classes.value}>
                              {invoicesToPay.length
                                ? invoicesToPay.find(
                                  (invoice) => invoice.CONTRATO === contract
                                ).NOMBRE
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className={classes.cardWrapp}>
                          {invoicesToPay.map((invoice) => {
                            if (invoice.CONTRATO !== contract) {
                              return null
                            }
                            return (
                              <PaymentDetailsCard
                                key={invoice.FACTURA}
                                invoice={invoice}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                  <div className={classes.infoBottom}>
                    <span className={classes.label}>TOTAL: </span>
                    <span className={classes.value}>
                      $
                      {invoicesToPay
                        .reduce(
                          (accumulator, invoice) =>
                            accumulator + +invoice.SALDO,
                          0
                        )
                        .toLocaleString('en-US')}{' '}
                      USD
                    </span>
                    <span className={classes.value}>
                      $
                      {invoicesToPay
                        .reduce(
                          (accumulator, invoice) =>
                            accumulator + +invoice.SALDODOP,
                          0
                        )
                        .toLocaleString('en-US')}{' '}
                      DOP
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </AppContainerWithMenu>
    </>
  )
}

export default connect((state) => ({
  invoicesToPay: state.invoices.invoicesToPay,
  isChatbot: state.chatbot.isChatbot
  // user: state.users.user,
}))(PaymentDetails)
