import React from 'react'

import { useMediaQuery } from 'react-responsive'

import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'

import classes from './styles.module.scss'
import waterIcon from '../../../assets/images/water.svg'
import lightningIcon from '../../../assets/images/lightning.svg'
import phoneIcon from '../../../assets/images/phone.svg'
import plusIcon from '../../../assets/images/plus.svg'

const COMPANIES = [
  {
    title: 'Aguas de CapCana',
    subtitle: 'Name of the Water Company',
    type: 'water',
    icon: waterIcon
  },
  {
    title: 'CCAR',
    subtitle: 'Name of the Electricity Company',
    type: 'electricity',
    icon: lightningIcon
  },
  {
    title: 'CCTEL',
    type: 'telephone',
    subtitle: 'Name of the Telephone Company',
    icon: phoneIcon
  }
]

export default function ContractsPage ({ history }) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })

  return (
    <>
      <div className={classes.ContractsPage}>
        {isSmallScreen ? <MobileHeader /> : <Header blueClip />}
        <div className={classes.ArcTopBackground} />
        <div className={classes.BubblesBottomBackround} />
        <div className={classes.title}>
          <h1> Your Contracts </h1>
        </div>
        <div className={classes.boxWrapper}>
          <div className={classes.contracts}>
            {COMPANIES.map((company) => {
              return (
                <div
                  key={company.type}
                  className={classes.contractItem}
                  onClick={() =>
                    history.push(
                      `/dashboard/contracts/list?companyType=${company.type}&companyName=${company.title}`
                    )
                  }
                >
                  <div className={classes.contractImg}>
                    <img src={company.icon} alt={company.type} />
                  </div>
                  <div className={classes.contractInfo}>
                    <div className={classes.label}>
                      <span>{company.title}</span>
                    </div>
                    <div className={classes.description}>
                      <span>{company.subtitle}</span>
                    </div>
                  </div>
                </div>
              )
            })}

            <div
              className={classes.addContractBtn}
              onClick={() => history.push('/signup/clientCode?addNew=true')}
            >
              <div className={classes.img}>
                <img src={plusIcon} alt="Plus" />
              </div>
              <div className={classes.text}>
                <span>Add Client ID</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
