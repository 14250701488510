import React from 'react'

import moment from 'moment'
import { useHistory } from 'react-router-dom'

import classes from './styles.module.scss'
import lightningIcon from '../../assets/images/lightningMin.svg'
import waterIcon from '../../assets/images/waterMin.svg'
import phoneIcon from '../../assets/images/phone.svg'

export default function CompanyCardHistory ({ transaction }) {
  // const [selected, setSelected] = useState(false);
  let icon

  const history = useHistory()

  if (transaction.sociedad === 'AGUA') {
    icon = waterIcon
  } else if (transaction.sociedad === 'CTEL') {
    icon = phoneIcon
  } else if (transaction.sociedad === 'CCAR') {
    icon = lightningIcon
  }

  return (
    <div
      // className={`${classes.CompanyCard} ${selected ? classes.borderCard : null}`}
      onClick={() => history.push(`/invoice?order=${transaction.orderId}`)}
      className={classes.CompanyCard}
    >
      <div className={classes.LeftWrap}>
        <div className={classes.boxImg}>
          {/* {selected ? (
            <img src={lightningIconBlue} alt="LightningBlue" />
          ) : (
            <img src={lightningIcon} alt="Lightning" />
          )} */}
          <img
            src={icon}
            alt="Icon"
            width={transaction.sociedad === 'CTEL' ? 30 : ''}
          />
        </div>
        <div
          className={classes.companyInfo}
          style={{
            position: 'relative',
            left: transaction.sociedad === 'CTEL' ? -7 : 0
          }}
        >
          <div className={classes.contactBox}>
            <span className={classes.label}>{transaction.sociedad}</span>
          </div>
          <div className={classes.centerItem}>
            <span className={classes.label}>Titular: </span>
            <span className={classes.value}>{transaction.nombre}</span>
          </div>
          <div className={classes.contactBox}>
            <span className={classes.label}>Fecha: </span>
            <span className={classes.value}>
              {moment(transaction.createdAt).format('MMM/DD/YYYY')}
            </span>
          </div>
          <div className={classes.centerItem} style={{ paddingBottom: 0 }}>
            <span className={classes.label}>Authorization number: </span>
            <span className={classes.value}>
              {transaction.authorizationNumber}
            </span>
          </div>
          <div
            className={classes.centerItem}
            style={{ marginBottom: 0, paddingBottom: 0 }}
          >
            <span className={classes.label}>CCPay ref#: </span>
            <span className={classes.value}>{transaction.orderCode}</span>
          </div>
        </div>
      </div>
      <div className={classes.amountBox}>
        <span className={classes.amount}>Amount:</span>
        <span className={classes.amountValue}>
          ${parseFloat(transaction.amount).toFixed(2).toLocaleString('en-US')}{' '}
          USD
        </span>
      </div>
    </div>
  )
}
