import React from 'react'
import { Modal } from 'react-bootstrap'
import classes from './styles.module.scss'
import closeIcon from '../../assets/images/close.svg'

const ModalLayout = (props) => {
  const { onHide, children } = props
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={classes.contentBox}
        className={classes.modal}
      >
        <Modal.Header className={classes.header}>
          <div onClick={onHide}>
            <img src={closeIcon} alt="Close" />
          </div>
        </Modal.Header>
        <Modal.Body className={classes.body}>{children}</Modal.Body>
      </Modal>
    </>
  )
}

export default ModalLayout
