import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_HAS_CLIENT_CODE
} from '../types'
import { saveAuthData, clearAuthData } from '../../helpers/authStorage'

import AuthService from '../../services/auth.service'
import UserService from '../../services/user.service'
import CustomerService from '../../services/customer.service'

const registerUser = (values) => () => {
  return AuthService.register(values)
}

const loginUser = ({ email, password }) => (dispatch) => {
  return AuthService.login(email, password)
    .then((response) => {
      if (response.data && response.data.accessToken) {
        saveAuthData(response.data)
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { dataAuth: response.data }
        })
      }
    })
    .then(() => UserService.getUser())
    .then(({ data }) => {
      const customer = CustomerService.getCustomer(data.pk)
      return Promise.resolve(customer)
    })
    .catch((error) => {
      if (error.response.data && error.response.status !== 401) {
        return Promise.resolve(null)
      }
      dispatch({
        type: LOGIN_FAIL
      })
      return Promise.reject(error)
    })
}

const loginUserSession = ({ sessionID, pathname }) => (dispatch) => {
  return AuthService.loginSession({ sessionID, pathname })
    .then((response) => {
      if (response.data && response.data.accessToken) {
        saveAuthData(response.data)
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { dataAuth: response.data }
        })
      }
    })
    .then(() => UserService.getUser())
    .then(({ data }) => {
      const customer = CustomerService.getCustomer(data.pk)
      return Promise.resolve(customer)
    })
    .catch((error) => {
      if (error.response.data && error.response.status !== 401) {
        return Promise.resolve(null)
      }
      dispatch({
        type: LOGIN_FAIL
      })
      return Promise.reject(error)
    })
}

const resetPasswordUser = ({ email }) => () => {
  return AuthService.resetPasswordUser(email)
    .then(() => {
      return Promise.resolve()
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

const createNewPassword = (values) => () => {
  return AuthService.createNewPassword(values)
    .then(({ data }) => {
      return Promise.resolve(data)
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

const logoutUser = () => (dispatch) => {
  clearAuthData()
  dispatch({
    type: LOGOUT
  })
}

const verifyEmail = (email, confirmationCode) => (dispatch) => {
  return AuthService.verifyEmail(email, confirmationCode)
    .then((response) => {
      if (response.data && response.data.accessToken) {
        saveAuthData(response.data)
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { dataAuth: response.data }
        })
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

const updateHasClientCode = () => (dispatch) => {
  dispatch({
    type: UPDATE_HAS_CLIENT_CODE
  })
}

export default {
  registerUser,
  loginUser,
  loginUserSession,
  resetPasswordUser,
  createNewPassword,
  logoutUser,
  verifyEmail,
  updateHasClientCode
}
