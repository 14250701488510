import React from 'react'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import classes from './styles.module.scss'

const ErrorMessage = ({ errors }) => {
  const { t } = useTranslation()
  return (
    <>
      {errors && (
        <Alert variant="danger" className={classes.containerAlert}>
          {t(`${errors}`)}
        </Alert>
      )}
    </>
  )
}

export default ErrorMessage
