import CardService from '../../services/card.service'
import {
  FETCHING,
  GET_CARDS_FAIL,
  GET_CARDS_SUCCESS,
  REMOVE_CARD_SUCCESS
} from '../types'

export const saveCard = (values) => () => {
  return CardService.saveCard(values)
}

export const getCards = () => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return CardService.getCards()
    .then(({ data }) => {
      dispatch({
        type: GET_CARDS_SUCCESS,
        payload: data
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: GET_CARDS_FAIL
      })
      return Promise.reject(error.response)
    })
}

export const deleteCard = (cardId) => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return CardService.deleteCard(cardId)
    .then(() => {
      dispatch({
        type: REMOVE_CARD_SUCCESS,
        payload: cardId
      })

      return Promise.resolve()
    })
    .catch((error) => {
      console.log(error)
      return Promise.reject(error.response)
    })
}
