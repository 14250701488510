import React from 'react'
import Title from '../../components/Account/Title'
import checkCircle from '../../assets/images/checkCircle.svg'
import classes from './styles.module.scss'

const InformationSendEmail = ({ email }) => {
  return (
    <>
      <Title text="Information" />
      <div className={classes.boxImg}>
        <img src={checkCircle} alt="checkCircle" />
      </div>
      <div className={classes.message}>
        <p>
          A letter was sent to the mail {email} with the specified further
          actions
        </p>
      </div>
    </>
  )
}

export default InformationSendEmail
