import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import classNames from 'classnames'
import * as customersActions from '../../../redux/actions/customer'
import * as usersActions from '../../../redux/actions/user'
import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'
import Title from '../../../components/Account/Title'
import FormButton from '../../../components/Form/StyledButton'

function Profile ({
  isFetching,
  user,
  customer,
  getUser,
  getCustomer,
  history
}) {
  useEffect(() => {
    getUser()
  }, [])
  useEffect(() => {
    if (user) {
      getCustomer(user.pk)
    }
  }, [user])

  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.ProfilePage}>
          <div className={classes.col}>
            <Title text="Profile" />
            <div className={classes.box_btn}>
              <FormButton
                style={{ height: 52 }}
                secondary
                onClick={() => {
                  history.push('/account/profile/edit')
                }}
                label={
                  <div className={classes.buttonLabel}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: 9 }}
                    >
                      <path
                        d="M12.222 14.1666H1.83312V3.7777H8.07589L9.02034 2.83325H1.83312C1.58263 2.83325 1.34241 2.93276 1.16529 3.10987C0.988176 3.28699 0.888672 3.52721 0.888672 3.7777V14.1666C0.888672 14.4171 0.988176 14.6573 1.16529 14.8344C1.34241 15.0115 1.58263 15.111 1.83312 15.111H12.222C12.4725 15.111 12.7127 15.0115 12.8898 14.8344C13.0669 14.6573 13.1664 14.4171 13.1664 14.1666V7.08325L12.222 8.0277V14.1666Z"
                        fill="#11578C"
                      />
                      <path
                        d="M14.8339 2.75768L13.2425 1.16629C13.1719 1.09547 13.088 1.03928 12.9956 1.00094C12.9032 0.962605 12.8042 0.942871 12.7042 0.942871C12.6042 0.942871 12.5051 0.962605 12.4128 1.00094C12.3204 1.03928 12.2365 1.09547 12.1658 1.16629L5.69168 7.67823L5.16751 9.94962C5.14518 10.0597 5.14753 10.1734 5.17439 10.2825C5.20125 10.3916 5.25194 10.4934 5.32284 10.5805C5.39373 10.6677 5.48306 10.738 5.5844 10.7865C5.68573 10.835 5.79656 10.8605 5.9089 10.861C5.96697 10.8674 6.02556 10.8674 6.08363 10.861L8.3739 10.3557L14.8339 3.83434C14.9047 3.76372 14.9609 3.67981 14.9992 3.58744C15.0376 3.49506 15.0573 3.39603 15.0573 3.29601C15.0573 3.19599 15.0376 3.09696 14.9992 3.00458C14.9609 2.91221 14.9047 2.8283 14.8339 2.75768ZM7.88279 9.48212L6.15446 9.86462L6.55585 8.15045L11.4292 3.24407L12.7608 4.57573L7.88279 9.48212ZM13.2945 4.04212L11.9628 2.71045L12.6947 1.96434L14.0358 3.30545L13.2945 4.04212Z"
                        fill="#11578C"
                      />
                    </svg>
                    <span>Edit profile</span>
                  </div>
                }
              />
            </div>
            {!isFetching && user && customer && (
              <div className={classes.info}>
                <div className={classes.info_item}>
                  <h3>{user.email}</h3>
                  <span>Name</span>
                </div>
                {customer.phone && (
                  <div
                    className={classNames(
                      classes.info_item,
                      customer.isPhoneConfirmed
                        ? classes.isValid
                        : classes.isInvalid
                    )}
                  >
                    <h3>{customer.phone}</h3>
                    <span>Phone number</span>
                  </div>
                )}
                {/* <div className={classes.info_item}>
                  <h3>{user.email}</h3>
                  <span>Email</span>
                </div> */}
                {customer.address && (
                  <div className={classes.info_item}>
                    <h3>{customer.address}</h3>
                    <span>Address</span>
                  </div>
                )}
                {customer.clientCode &&
                  customer.clientCode.split(',').map((code) => {
                    return (
                      <div className={classes.info_item} key={code}>
                        <h3>{code}</h3>
                        <span>Client ID</span>
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      </AppContainerWithMenu>
    </>
  )
}

export default connect(
  (state) => ({
    isFetching: state.customers.isFetching,
    user: state.users.user,
    customer: state.customers.customer
  }),
  {
    getUser: usersActions.getUser,
    getCustomer: customersActions.getCustomer
  }
)(Profile)
