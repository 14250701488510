import React, { useEffect } from 'react'

import { Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'

import AppContainerWithMenu from '../../../containers/layouts/ContainerWithMenu'
import classes from './styles.module.scss'
import Title from '../../../components/Account/Title'
import CompanyCardHistory from '../../../components/TransactionHistoryCard'
import * as transactionActions from '../../../redux/actions/transaction'
import * as userActions from '../../../redux/actions/user'

function generateHistory (transactions, user) {
  const history = []
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const currentDate = new Date()
  const currentYearTransactions = transactions.filter((transaction) => {
    return moment(transaction.createdAt).isSame(moment(currentDate), 'year')
  })

  months.forEach((month) => {
    const currentMonthTransactions = currentYearTransactions.filter(
      (transaction) => {
        return moment(transaction.createdAt).format('MMMM') === month
      }
    )
    history.push(
      <div
        className={classes.groupItem}
        style={{ display: currentMonthTransactions.length ? 'block' : 'none' }}
        key={month}
      >
        <div className={classes.contact}>
          <span className={classes.label}>{month}</span>
        </div>
        {currentMonthTransactions.map((transaction) => (
          <CompanyCardHistory
            transaction={transaction}
            user={user}
            key={transaction.id}
          />
        ))}
      </div>
    )
  })
  return history
}

export function TransactionHistoryPage ({
  getTransactions,
  transactions,
  user,
  getUser
}) {
  useEffect(() => {
    getTransactions()
    getUser()
  }, [])

  return (
    <>
      <AppContainerWithMenu>
        <div className={classes.ContractsGroups}>
          <div className={classes.col}>
            <Card className={classes.boxCompanyGroupList}>
              <Card.Body className={classes.boxCompanyListBody}>
                <div className={classes.title}>
                  <Title text="Transaction History" />
                </div>
                <div className={classes.companyGroups}>
                  {transactions && user && generateHistory(transactions, user)}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </AppContainerWithMenu>
    </>
  )
}

export default connect(
  (state) => ({
    transactions: state.transactions.transactions,
    user: state.users.user
  }),
  {
    getTransactions: transactionActions.getTransactions,
    getUser: userActions.getUser
  }
)(TransactionHistoryPage)
