import React from 'react'
import { useHistory } from 'react-router-dom'

import Form from 'react-bootstrap/Form'
import { Card } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Formik, Field } from 'formik'
import classnames from 'classnames'

import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import FormField from '../../../components/Form/FormField'
import PhoneField from '../../../components/Form/PhoneField'
import FormButton from '../../../components/Form/StyledButton'
import classes from './styles.module.scss'

const validationPhone = (value) => {
  let errorMessage
  if (!isValidPhoneNumber(value)) {
    errorMessage = value ? 'Invalid phone number' : 'Please enter your phone'
  }
  return errorMessage
}

export default function CreditCardRegistrationPage () {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  const history = useHistory()
  return (
    <>
      <div className={classes.CreditCardRegistrationPage}>
        {isSmallScreen ? <MobileHeader /> : <Header blueClip />}
        <div className={classes.ArcTopBackground} />
        <div className={classes.BubblesBottomBackround} />
        <div className={classes.title}>
          <h1> Credit card registration </h1>
          <p>Billing address</p>
        </div>
        <Card className={classes.boxCreditCardRegistration}>
          <Card.Body className={classes.boxCreditCardRegistrationBody}>
            <Formik
              initialValues={{
                name: '',
                street: '',
                suite: '',
                city: '',
                phone: ''
              }}
              onSubmit={(values) => {
                console.log(values)
                history.push('/signup')
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
                // isSubmitting,
              }) => (
                <Form className={classes.form}>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      label="Name"
                      type="text"
                      placeholder="Enter your name"
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                      value={values.name}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      label="Street"
                      type="text"
                      placeholder="Enter your street"
                      value={values.street}
                      onChange={handleChange('street')}
                      onBlur={handleBlur('street')}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      label="Apt/Suite"
                      type="text"
                      placeholder="Enter your apt/suite"
                      value={values.suite}
                      onChange={handleChange('suite')}
                      onBlur={handleBlur('suite')}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      label="City"
                      type="text"
                      placeholder="Enter your city"
                      value={values.city}
                      onChange={handleChange('city')}
                      onBlur={handleBlur('city')}
                    />
                  </div>
                  {/* <div
                    className={classnames(
                      classes.formFieldContainer,
                      classes.phoneWithOutSelect
                    )}
                  >
                    <FormField
                      label="Phone number"
                      type="text"
                      placeholder=""
                      value={phone}
                      onChange={(event) => {
                        setPhone(event.target.value)
                      }}
                    />
                  </div> */}
                  <div
                    className={classnames(
                      classes.formFieldContainer,
                      classes.phoneWithSelect
                    )}
                  >
                    <Field
                      label="Phone number"
                      type="text"
                      name="phone"
                      onChange={handleChange('phone')}
                      onBlur={handleBlur('phone')}
                      component={PhoneField}
                      validate={validationPhone}
                    />
                    {touched.phone && errors.phone
                      ? <ErrorMessage message={errors.phone} />
                      : null}
                  </div>
                  <div
                    className={classes.formFieldContainer}
                    style={{ marginBottom: 60, marginTop: 68 }}
                  >
                    <FormButton label="Save" onClick={handleSubmit} />
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
