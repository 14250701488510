import React from 'react'

import { useHistory } from 'react-router-dom'
import classnames from 'classnames'

import classes from './styles.module.scss'

export default function EmptyHeaderWithLogo ({
  biggerVariant,
  wavy,
  incorrectPasswordVariant,
  createNewPassword
}) {
  const history = useHistory()
  return (
    <header
      className={classnames(
        classes.EmptyHeaderWithLogo,
        biggerVariant && classes.bigger,
        wavy && classes.wavy,
        incorrectPasswordVariant && classes.incorrectPassword
      )}
    >
      {incorrectPasswordVariant && !createNewPassword && (
        <div className={classes.headerButtons}>
          <i className={classes.goBack} onClick={() => history.goBack()}>
            GoBack
          </i>
          <i className={classes.help}>Help</i>
        </div>
      )}
      <div className={classes.logo} />
      {biggerVariant && (
        <h1 className={classes.title}>
          {createNewPassword ? 'Create new password' : 'Incorrect password'}
        </h1>
      )}
    </header>
  )
}
