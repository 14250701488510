import {
  GET_TRANSACTIONS_SUCCESS,
  GET_ORDER_SUCCESS,
  FETCHING
} from '../types'

import TransactionService from '../../services/transaction.service'

export const getTransactions = () => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return TransactionService.getTransactions()
    .then(({ data }) => {
      dispatch({
        type: GET_TRANSACTIONS_SUCCESS,
        payload: data.transactions
      })
      dispatch({
        type: FETCHING,
        payload: false
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: FETCHING,
        payload: false
      })
      return Promise.reject(error.response)
    })
}

export const getOrder = (orderId) => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return TransactionService.getOrder(orderId)
    .then(({ data }) => {
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: data.order
      })
      dispatch({
        type: FETCHING,
        payload: false
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: FETCHING,
        payload: false
      })
      return Promise.reject(error.response)
    })
}
