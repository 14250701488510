import axios from 'axios'

import { getAuthData, saveAuthData } from './authStorage'
import { API_URL } from '../constants/main'

export default function forceTokenRefresh () {
  const { refreshToken, userId } = getAuthData()
  return axios
    .post(`${API_URL}/auth-token-refresh`, { refreshToken, userId })
    .then((response) => {
      saveAuthData({
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        userId: response.data.userId
      })
    })
    .catch((error) => {
      console.log(error)
    })
}
