// app
export const FETCHING = 'FETCHING'
export const UPDATING = 'UPDATING'

// auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'POST/LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const LOGOUT = 'LOGOUT'
export const UPDATE_HAS_CLIENT_CODE = 'UPDATE_HAS_CLIENT_CODE'

// user
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'

// customer
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL'

// contract
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS'
export const GET_CONTRACTS_FAIL = 'GET_CONTRACTS_FAIL'
export const ADD_CONTRACT_TO_PAY = 'ADD_CONTRACT_TO_PAY'
export const REMOVE_CONTRACT_TO_PAY = 'REMOVE_CONTRACT_TO_PAY'
export const RESET_CONTRACTS_TO_PAY = 'RESET_CONTRACTS_TO_PAY'
export const SET_CONTRACTS_TO_PAY_COMPANY_NAME =
  'SET_CONTRACTS_TO_PAY_COMPANY_NAME'

// invoice
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS'
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL'
export const ADD_INVOICE_TO_PAY = 'ADD_INVOICE_TO_PAY'
export const REMOVE_INVOICE_TO_PAY = 'REMOVE_INVOICE_TO_PAY'
export const RESET_INVOICES_TO_PAY = 'RESET_INVOICES_TO_PAY'

// card
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS'
export const GET_CARDS_FAIL = 'GET_CARDS_FAIL'
export const REMOVE_CARD_SUCCESS = 'REMOVE_CARD_SUCCESS'

// transaction
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL'

// chatbot
export const IS_CHATBOT = 'IS_CHATBOT'
