import React, { useState } from 'react'

import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import Form from 'react-bootstrap/Form'
import classnames from 'classnames'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'

import * as cardActions from '../../../redux/actions/card'
import toFormErrors from '../../../helpers/toFormErrors'

import FormField from '../../../components/Form/FormField'
import CardNumberField from '../../../components/Form/CardNumberField'
// import ExpCardDateField from '../../../components/Form/ExpCardDateField';
import FormButton from '../../../components/Form/StyledButton'
import Header from '../../../components/Header'
import MobileHeader from '../../../components/MobileHeader'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import FormErrorMessage from '../../../components/Messages/ErrorMessage'
import CountryField from '../../../components/Form/CountryField'
import DropDown from '../../../components/Form/DropDown'

import creditCardImage from '../../../assets/images/creditCard.svg'
import classes from './styles.module.scss'

const digitsAndSpacesOnly = (value) => /[0-9 ]+/.test(value)
const digitsOnly = (value) => /^\d+$/.test(value)

const ValidationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required('Please enter card number')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(19, 'This field must contain 16 digits'),
  name: Yup.string().required('Please enter your name'),
  expirationMonth: Yup.string()
    .required('Please enter card expiration month')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(2, 'This field must contain 2 digits'),
  expirationYear: Yup.string()
    .required('Please enter card expiration year')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(4, 'This field must contain 4 digits'),
  securityCode: Yup.string()
    .required('Please enter card\'s cvv')
    .test('Digits only', 'The field should have digits only', digitsOnly)
    .length(3, 'This field must contain 3 digits'),
  address: Yup.string().required('Please enter your address'),
  country: Yup.string().required('Please enter your country'),
  state: Yup.string().required('Please enter your state'),
  city: Yup.string().required('Please enter your city'),
  zip: Yup.string()
    .required('Please enter your ZIP code')
    .test('Digits only', 'The field should have digits only', digitsOnly)
})

const years = []

for (
  let i = new Date().getFullYear();
  i <= new Date().getFullYear() + 30;
  // eslint-disable-next-line no-plusplus
  i++
) {
  years.push(i)
}

function CreditCardRegistrationPage ({ saveCard }) {
  const [cardRadioButton, setCardRadioButton] = useState('radio1')
  const [error, setErrors] = React.useState(null)

  const isMobile = useMediaQuery({ query: '(max-width: 570px)' })
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })

  const changeRadioButtonValue = (event) => {
    setCardRadioButton(event.target.value)
  }

  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const query = new URLSearchParams(useLocation().search)

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <div className={classes.CreditCardRegistrationPage}>
      {isSmallScreen ? <MobileHeader /> : <Header blueClip />}
      <div className={classes.backgroundContainer} />
      <h1 className={classes.title}>Credit card registration</h1>
      <img
        src={creditCardImage}
        alt="Credit card"
        className={classes.creditCardImage}
      />
      <Formik
        initialValues={{
          cardNumber: '',
          name: '',
          expirationMonth: '',
          expirationYear: '',
          securityCode: '',
          address: '',
          zip: '',
          country: '',
          state: '',
          city: ''
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          saveCard({
            firstName: values.name.split(' ')[0],
            lastName: values.name.split(' ')[1],
            cardNumber: values.cardNumber,
            expirationMonth: values.expirationMonth,
            expirationYear: values.expirationYear,
            cvv: values.securityCode,
            country: values.country,
            address: values.address,
            zip: values.zip,
            state: values.state,
            city: values.city,
            isDefault: false
            // query.get('default') === null ? true : query.get('default'),
          })
            .then(() => {
              setErrors(null)
              history.push('/account/wallet')
            })
            .catch((err) => {
              setErrors(toFormErrors(err.response))
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          // isSubmitting,
        }) => (
          <Form className={classes.form}>
            <div className={classes.radioButtons}>
              <label className={classes.radioButtonContainer}>
                <input
                  type="radio"
                  name="card"
                  value="radio1"
                  checked={cardRadioButton === 'radio1'}
                  onChange={changeRadioButtonValue}
                />
                <span className={classes.checkmark} />
              </label>
              <label className={classes.radioButtonContainer}>
                <input
                  type="radio"
                  name="card"
                  value="radio2"
                  checked={cardRadioButton === 'radio2'}
                  onChange={changeRadioButtonValue}
                />
                <span className={classes.checkmark} />
              </label>
            </div>
            <div className={classes.formFieldContainer}>
              <CardNumberField
                label="Card number"
                type="text"
                placeholder=""
                value={values.cardNumber}
                onChange={handleChange('cardNumber')}
                onBlur={handleBlur('cardNumber')}
              />
              {touched.cardNumber && errors.cardNumber
                ? (
                <ErrorMessage message={errors.cardNumber} />
                  )
                : null}
            </div>
            <div className={classes.formFieldContainer}>
              <FormField
                label="Credit card holder"
                type="text"
                placeholder=""
                value={values.name}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
              />
              {touched.name && errors.name
                ? (
                <ErrorMessage message={errors.name} />
                  )
                : null}
            </div>
            <div className={classes.shortFieldsContainer}>
              <div
                className={classnames(
                  classes.formFieldContainer,
                  classes.short
                )}
              >
                <DropDown
                  name="expirationMonth"
                  label="Month"
                  type="text"
                  placeholder=""
                  value={values.expirationMonth}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur('expirationMonth')}
                  options={[
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12'
                  ]}
                />
                {touched.expirationMonth && errors.expirationMonth
                  ? (
                  <ErrorMessage message={errors.expirationMonth} />
                    )
                  : null}
              </div>
              <div
                className={classnames(
                  classes.formFieldContainer,
                  classes.short
                )}
              >
                <DropDown
                  name="expirationYear"
                  label="Year"
                  type="text"
                  placeholder=""
                  value={values.expirationYear}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur('expirationYear')}
                  options={years}
                />
                {touched.expirationYear && errors.expirationYear
                  ? (
                  <ErrorMessage message={errors.expirationYear} />
                    )
                  : null}
              </div>
              <div
                className={classnames(
                  classes.formFieldContainer,
                  classes.short
                )}
              >
                <FormField
                  label="CVV"
                  type="text"
                  placeholder=""
                  value={values.securityCode}
                  onChange={handleChange('securityCode')}
                  onBlur={handleBlur('securityCode')}
                />
                {touched.securityCode && errors.securityCode
                  ? (
                  <ErrorMessage message={errors.securityCode} />
                    )
                  : null}
              </div>
            </div>
            <div className={classes.formFieldContainer}>
              <FormField
                label="Address"
                type="text"
                placeholder=""
                value={values.address}
                onChange={handleChange('address')}
                onBlur={handleBlur('address')}
              />
              {touched.address && errors.address
                ? (
                <ErrorMessage message={errors.address} />
                  )
                : null}
            </div>

            <div className={classes.formFieldContainer}>
              <FormField
                label="State"
                type="text"
                value={values.state}
                onChange={handleChange('state')}
                onBlur={handleBlur('state')}
              />
              {touched.state && errors.state
                ? (
                <ErrorMessage message={errors.state} />
                  )
                : null}
            </div>
            <div className={classes.formFieldContainer}>
              <FormField
                label="City"
                type="text"
                value={values.city}
                onChange={handleChange('city')}
                onBlur={handleBlur('city')}
              />
              {touched.city && errors.city
                ? (
                <ErrorMessage message={errors.city} />
                  )
                : null}
            </div>
            <div className={classes.formFieldContainer}>
              <CountryField
                label="Country"
                name="country"
                type="text"
                placeholder="Enter your country"
                value={values.country}
                setFieldValue={setFieldValue}
                onBlur={handleBlur('country')}
              />
              {touched.country && errors.country
                ? (
                <ErrorMessage
                  message={
                    values.country === ''
                      ? 'Please enter your country'
                      : errors.country
                  }
                />
                  )
                : null}
            </div>
            <div
              className={classes.formFieldContainer}
              style={{ marginBottom: isMobile ? 52 : 34 }}
            >
              <FormField
                label="Zip code"
                type="text"
                placeholder=""
                value={values.zip}
                onChange={handleChange('zip')}
                onBlur={handleBlur('zip')}
              />
              {touched.zip && errors.zip
                ? (
                <ErrorMessage message={errors.zip} />
                  )
                : null}
            </div>
            {error && <FormErrorMessage errors={error} />}
            <div
              className={classes.formFieldContainer}
              style={{ marginBottom: 60 }}
            >
              <FormButton label="Save" onClick={handleSubmit} />
              {query.get('afterSignUp') && (
                <FormButton
                  subtle
                  label="Skip"
                  onClick={() => history.push('/dashboard/contracts')}
                  style={{ marginTop: 15 }}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default connect(null, {
  saveCard: cardActions.saveCard
})(CreditCardRegistrationPage)
