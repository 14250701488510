import React from 'react'

import { connect } from 'react-redux'
import { Card, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'

import authActions from '../../../redux/actions/auth'

import toFormErrors from '../../../helpers/toFormErrors'
import ModalLayout from '../../../modals/ModalLayout'
import InformationSendEmail from '../../../modals/InformationSendEmail'
import FormField from '../../../components/Form/FormField'
import FormButton from '../../../components/Form/StyledButton'
import CheckBox from '../../../components/Form/CheckBox'
import EmptyHeader from '../../../components/EmptyHeader'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import FormErrorMessage from '../../../components/Messages/ErrorMessage'

import classes from './styles.module.scss'

const ValidationSchema = Yup.object().shape({
  // username: Yup.string().required('Please enter your username'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  password: Yup.string().min(2, 'Too Short!').required('Please enter password'),
  passwordConfirmation: Yup.string()
    .min(2, 'Too Short!')
    .required('Please confirm your password')
    .oneOf([Yup.ref('password'), null], 'Password mismatch'),
  agree: Yup.bool().oneOf(
    [true],
    "You can't continue without agreeing to terms of use"
  )
})

function SignUpPage ({ registerUser }) {
  const [modalShow, setModalShow] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [error, setErrors] = React.useState(null)

  const history = useHistory()

  return (
    <div className={classes.SignUpPage}>
      <EmptyHeader />
      <div className={classes.backgroundContainer} />
      <Card className={classes.signupForm}>
        <Card.Body className={classes.signupFormBody}>
          <Card.Title className={classes.formTitle}>Sign up</Card.Title>
          <Formik
            initialValues={{
              username: '',
              email: '',
              password: '',
              passwordConfirmation: '',
              agree: false
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              setEmail(values.email)
              registerUser({
                username: values.username,
                password1: values.password,
                password2: values.passwordConfirmation,
                email: values.email
              })
                .then(() => {
                  setModalShow(true)
                  setErrors(null)
                })
                .catch((err) => {
                  setErrors(toFormErrors(err.response))
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
              // isSubmitting,
            }) => (
              <Form className={classes.form} noValidate>
                {/* <div className={classes.formFieldContainer}>
                  <FormField
                    label="Username"
                    type="username"
                    placeholder="Enter username"
                    onChange={handleChange('username')}
                    onBlur={handleBlur('username')}
                    value={values.username}
                  />
                  {touched.username && errors.username ? (
                    <ErrorMessage message={errors.username} />
                  ) : null}
                </div> */}
                <div className={classes.formFieldContainer}>
                  <FormField
                    label="Email"
                    type="email"
                    placeholder="Enter email"
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                  />
                  {touched.email && errors.email
                    ? (
                    <ErrorMessage message={errors.email} />
                      )
                    : null}
                </div>
                <div className={classes.formFieldContainer}>
                  <FormField
                    label="Password"
                    type="password"
                    placeholder="Enter password"
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    value={values.password}
                  />
                  {touched.password && errors.password
                    ? (
                    <ErrorMessage message={errors.password} />
                      )
                    : null}
                </div>
                <div className={classes.formFieldContainer}>
                  <FormField
                    label="Re-enter password"
                    type="password"
                    placeholder="Confirm password"
                    onChange={handleChange('passwordConfirmation')}
                    onBlur={handleBlur('passwordConfirmation')}
                    value={values.passwordConfirmation}
                  />
                  {touched.passwordConfirmation &&
                  errors.passwordConfirmation
                    ? (
                    <ErrorMessage message={errors.passwordConfirmation} />
                      )
                    : null}
                </div>
                <div className={classes.formFieldContainer}>
                  <CheckBox
                    label="&nbsp;"
                    checked={values.agree}
                    name="agree"
                    onChange={handleChange('agree')}
                  />
                  <div className={classes.termsOfUse}>
                    Agree to <Link to="/terms-of-use">Terms of Use</Link>
                  </div>
                  {touched.agree && errors.agree
                    ? (
                    <ErrorMessage message={errors.agree} />
                      )
                    : null}
                </div>
                {error && <FormErrorMessage errors={error} />}
                <div className={classes.formFieldContainer}>
                  <FormButton label="Continue" onClick={handleSubmit} />
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <ModalLayout
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          history.push(`/signup/confirmEmail?email=${email}`)
        }}
      >
        <InformationSendEmail email={email} />
      </ModalLayout>
    </div>
  )
}

export default connect(
  (state) => ({
    isFetching: state.users.isFetching
  }),
  {
    registerUser: authActions.registerUser
  }
)(SignUpPage)
