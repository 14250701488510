import React, { useState } from 'react'

import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import * as Yup from 'yup'

import authActions from '../../../redux/actions/auth'

import ModalLayout from '../../../modals/ModalLayout'
import InformationSuccess from '../../../modals/InformationSuccess'
import FormErrorMessage from '../../../components/Messages/ErrorMessage'
import EmptyHeaderWithLogo from '../../../components/EmptyHeaderWithLogo'
import FormField from '../../../components/Form/FormFieldWithEye'
import FormButton from '../../../components/Form/StyledButton'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import classes from './styles.module.scss'

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required('Please enter new password'),
  passwordConfirmation: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password'), null], 'Password mismatch')
})

function CreateNewPasswordPage ({ createNewPassword, history }) {
  const [modalShow, setModalShow] = React.useState(false)
  const [text, setText] = useState('')

  const { search } = useLocation()
  const query = React.useMemo(() => new URLSearchParams(search), [search])

  const [error, setErrors] = useState(null)
  return (
    <div className={classes.CreateNewPasswordPage}>
      <EmptyHeaderWithLogo
        biggerVariant
        incorrectPasswordVariant
        createNewPassword
      />
      <div className={classes.contentContainer}>
        <p className={classes.details}>
          Enter your new password for your Cap Cana account.
        </p>
        <Formik
          initialValues={{ password: '', passwordConfirmation: '' }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            console.log('TOKEN', query.get('token'))
            createNewPassword({
              newPassword: values.password,
              token: query.get('token')
            })
              .then((data) => {
                setText(data.detail)
                setModalShow(true)
                setErrors(null)
                setTimeout(() => {
                  setModalShow(false)
                  history.push('/login')
                }, 4000)
              })
              .catch((err) => {
                console.log(err)
                console.log(err.data)
                setErrors('Error: URL is invalid or expired')
              })
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
            // isSubmitting,
          }) => (
            <Form>
              <div className={classes.formFieldContainer}>
                <FormField
                  label="New password"
                  type="password"
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  value={values.password}
                />
                {touched.password && errors.password
                  ? (
                  <ErrorMessage message={errors.password} outside />
                    )
                  : null}
              </div>
              <div className={classes.formFieldContainer}>
                <FormField
                  label="Re-enter password"
                  type="password"
                  onChange={handleChange('passwordConfirmation')}
                  onBlur={handleBlur('passwordConfirmation')}
                  value={values.passwordConfirmation}
                />
                {touched.passwordConfirmation && errors.passwordConfirmation
                  ? (
                  <ErrorMessage message={errors.passwordConfirmation} outside />
                    )
                  : null}
              </div>
              {error && (
                <div className={classes.errorMessage}>
                  <FormErrorMessage errors={error} />
                </div>
              )}
              <div className={classes.buttonContainer}>
                <FormButton label="Change my password" onClick={handleSubmit} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ModalLayout show={modalShow} onHide={() => setModalShow(false)}>
        <InformationSuccess text={text} />
      </ModalLayout>
    </div>
  )
}

export default connect(null, {
  createNewPassword: authActions.createNewPassword
})(CreateNewPasswordPage)
