import {
  GET_INVOICES_SUCCESS,
  FETCHING,
  GET_INVOICES_FAIL,
  ADD_INVOICE_TO_PAY,
  REMOVE_INVOICE_TO_PAY,
  RESET_INVOICES_TO_PAY
} from '../types'

const initialState = {
  invoices: [],
  invoicesToPay: [],
  isFetching: false
}

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: payload,
        isFetching: false
      }
    case GET_INVOICES_FAIL:
      return {
        ...state,
        isFetching: false
      }
    case FETCHING:
      return {
        ...state,
        isFetching: payload
      }
    case ADD_INVOICE_TO_PAY:
      return {
        ...state,
        invoicesToPay: [...state.invoicesToPay, payload]
      }
    case REMOVE_INVOICE_TO_PAY:
      return {
        ...state,
        invoicesToPay: state.invoicesToPay.filter(
          (invoice) => invoice !== payload
        )
      }
    case RESET_INVOICES_TO_PAY:
      return {
        ...state,
        invoicesToPay: []
      }
    default:
      return state
  }
}
