import {
  FETCHING,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  ADD_INVOICE_TO_PAY,
  REMOVE_INVOICE_TO_PAY,
  RESET_INVOICES_TO_PAY
} from '../types'
import InvoicesService from '../../services/invoice.service'

export const getInvoices = () => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: true
  })
  return InvoicesService.getInvoices()
    .then(({ data }) => {
      dispatch({
        type: GET_INVOICES_SUCCESS,
        payload: data
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: GET_INVOICES_FAIL
      })
      return Promise.reject(error.response)
    })
}

export const addInvoiceToPay = (invoice) => (dispatch) => {
  dispatch({
    type: ADD_INVOICE_TO_PAY,
    payload: invoice
  })
}

export const removeInvoiceToPay = (invoice) => (dispatch) => {
  dispatch({
    type: REMOVE_INVOICE_TO_PAY,
    payload: invoice
  })
}

export const resetInvoicesToPay = () => (dispatch) => {
  dispatch({
    type: RESET_INVOICES_TO_PAY
  })
}
